import * as authActions from './auth/auth.actions';
import * as cabinetsActions from './cabinets/cabinets.actions';
import { cabinetsSlice } from './cabinets/cabinets.slice';
import { calendarSlice } from './calendar/calendar.slice';
import { callRecordsSlice } from './call-records/callRecords.slice';
import { cashRegisterSlice } from './cash-register/cashRegister.slice';
import * as classificatorsActions from './classificators/classificators.actions';
import * as clientsActions from './clients/clients.actions';
import { clientsSlice } from './clients/clients.slice';
import { filialSlice } from './filial/filial.slice';
import * as financeActions from './finance/finance.actions';
import * as lessonsActions from './lessons/lessons.actions';
import { lessonsSlice } from './lessons/lessons.slice';
import { modalSlice } from './modal/modal.slice';
import * as notesActions from './notes/notes.actions';
import * as paymentsActions from './payments/payments.actions';
import { personalSiteSlice } from './personalsite/personalsite.slice';
import * as productsAction from './products/product.actions';
import { productsSlice } from './products/products.slice';
import { recordingSlice } from './recording/recording.slice';
import * as saleproductsAction from './saleproducts/saleproducts.actions';
import { saleproductsSlice } from './saleproducts/saleproducts.slice';
import { saleproductscategorySlice } from './saleproductscategory/saleproductscategory.slice';
import * as salesActions from './sales/sales.actions';
import { salesSlice } from './sales/sales.slice';
import { serviceSlice } from './service/service.slice';
import { sidebarSlice } from './sidebar/sidebar.slice';
import * as subproductsActions from './subproducts/subproducts.actions';
import * as tarifActions from './tarif/tarif.actions';
import { tarifSlice } from './tarif/tarif.slice';
import * as tasksActions from './tasks/tasks.actions';
import * as userActions from './user/user.actions';
import { userSlice } from './user/user.slice';
import * as chatActions from './websocket/chat-reducer';
import { chatSlice } from './websocket/chat-slice';

export const allActions = {
  ...salesSlice.actions,
  ...calendarSlice.actions,
  ...recordingSlice.actions,
  ...callRecordsSlice.actions,
  ...modalSlice.actions,
  ...sidebarSlice.actions,
  ...chatSlice.actions,
  ...userSlice.actions,
  ...productsSlice.actions,
  ...clientsSlice.actions,
  ...tarifSlice.actions,
  ...paymentsActions,
  ...notesActions,
  ...classificatorsActions,
  ...authActions,
  ...userActions,
  ...filialSlice.actions,
  ...subproductsActions,
  ...clientsActions,
  ...cabinetsSlice.actions,
  ...cabinetsActions,
  ...financeActions,
  ...lessonsActions,
  ...tasksActions,
  ...productsAction,
  ...saleproductsAction,
  ...saleproductsSlice.actions,
  ...saleproductscategorySlice.actions,
  ...salesActions,
  ...tarifActions,
  ...chatActions,
  ...serviceSlice.actions,
  ...cashRegisterSlice.actions,
  ...personalSiteSlice.actions,
  ...lessonsSlice.actions
};
