import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { addDateBy, timeStringToNumber } from './date-events';

import { IEmployeeScheduleQuery } from '../components/shared/modal/update/employee-schedule/schedule/EmployeeSchedule.data';
import {
  IGroupedSales,
  IGroupedTransactions
} from '../pages/transactions-all/ui/lib/transactions-period/transactions-period-data/TransactionsPeriodData.props';
import { IFilial } from '../store/redux/filial/filial.interface';
import { ISaleByDate } from '../store/redux/sales/sales.interface';
import { IBreak, IEmployeeSchedule, IUserSchedule } from '../store/redux/user/user.interface';

export const transformStringCountServices = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'услуга';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'услуги';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'услуг';
  } else {
    transformedString = 'услуг';
  }

  return transformedString;
};

export const transformStringCountSeats = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'место';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'места';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'мест';
  } else {
    transformedString = 'мест';
  }

  return transformedString;
};

export const transformStringCountDays = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'день';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'дня';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'дней';
  } else {
    transformedString = 'дней';
  }

  return transformedString;
};

export const transformStringCountEmps = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'сотрудник';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'сотрудника';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'сотрудников';
  } else {
    transformedString = 'сотрудников';
  }

  return transformedString;
};
export const getNumberCompleteYears = (date: string) => {
  if (!date) {
    return 'Дата не указана';
  }
  const birthdate = new Date(date);
  const currentdate = new Date();

  let age = currentdate.getFullYear() - birthdate.getFullYear();
  let ageStr = '';
  if (
    currentdate.getMonth() < birthdate.getMonth() ||
    (currentdate.getMonth() === birthdate.getMonth() && currentdate.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  if (age % 10 === 1 && (age < 10 || age > 20)) {
    ageStr = ' год';
  } else if (age % 10 >= 2 && age % 10 <= 4 && (age < 10 || age > 20)) {
    ageStr = ' года';
  } else if (((age % 10 >= 5 && age % 10 <= 9) || age % 10 === 0) && (age < 10 || age > 20)) {
    ageStr = ' лет';
  } else {
    ageStr = ' лет';
  }

  return age + ageStr;
};

export const transformFetchFilialScheduleData = (filial: IFilial | undefined) => {
  const resultArr: IEmployeeScheduleQuery | any[] = [];

  if (!filial) {
    return resultArr;
  }
  if (filial.timework && filial.timework[0]) {
    // const resultArr: IEmployeeScheduleQuery | any[] = [];
    const timeWorkObj = filial.timework[0];
    const throughWeek = addDateBy(new Date(), 6);
    for (let currDate = new Date(); currDate <= throughWeek; currDate.setDate(currDate.getDate() + 1)) {
      const currWeekDay = dayjs(currDate).locale('en').format('dddd').toLocaleLowerCase();
      if (timeWorkObj[currWeekDay].timestart !== '' && timeWorkObj[currWeekDay].timeend !== '') {
        resultArr.push({
          date: dayjs(new Date(currDate)).format('YYYY-MM-DD'),
          timestart: timeWorkObj[currWeekDay].timestart,
          timeend: timeWorkObj[currWeekDay].timeend
        });
      }
    }

    // return resultArr;
  }

  return resultArr;
};
export const rangeArr = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const numberToTime = (num: number) => {
  return num <= 9 ? '0' + String(num) + ':00' : String(num) + ':00';
};

export const dateToDateTime = (date: string, time: string) => {
  return date + ' ' + time;
};

export const generateRandomString = () => Math.random().toString(36).substring(3, 16);

export const transformEmployeeScheduleData = (employeeScheduleData: IUserSchedule[]) => {
  let resultArr: any[] = [];
  if (employeeScheduleData && employeeScheduleData.length > 0) {
    resultArr = employeeScheduleData.map((day) =>
      rangeArr(timeStringToNumber(day.timestart), timeStringToNumber(day.timeend) - 1, 1).map((hour) => {
        return {
          id: generateRandomString(),
          day: dayjs(day.date).locale('ru').format('dd').toLocaleLowerCase(),
          date: dayjs(day.date).locale('en').format('ddd MMM DD YYYY'),
          time: numberToTime(hour),
          timeEnd: numberToTime(hour + 1)
        };
      })
    );
  }

  return resultArr.flat(1);
};

export const transformEmployeeSheduleToApiData = (
  employeeSheduleArr: { id?: string; day?: string; date: string; time: string; timeEnd: string }[]
) => {
  const uniqueDates = new Set();
  let outputArray: any = [];
  if (employeeSheduleArr && employeeSheduleArr.length > 0) {
    employeeSheduleArr.forEach((item) => {
      if (!uniqueDates.has(item.date)) {
        uniqueDates.add(item.date);
      }
    });
    outputArray = Array.from(uniqueDates).map((item) => {
      return {
        date: item,
        timestart: employeeSheduleArr.filter((evt) => evt.date === item).reduce((acc, curr) => (acc.time > curr.time ? curr : acc)).time,
        timeend: employeeSheduleArr.filter((evt) => evt.date === item).reduce((acc, curr) => (acc.timeEnd < curr.timeEnd ? curr : acc))
          .timeEnd
      };
    });
  }

  return outputArray;
};
export const formatPhoneNumber = (phoneNumber: string) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');

  return formattedPhoneNumber;
};

export const transformTarifName = (tarifName: string) => {
  if (tarifName === 'Standart') {
    return 'Профессиональный';
  } else if (tarifName === 'Trial') {
    return 'Пробный';
  } else {
    return 'Базовый';
  }
};

export const convertCurrency = (amount: string | number) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  }).format(+amount);
};

export const getTimeDuration = (timeStart: string, timeEnd: string) => {
  if (!timeStart || timeStart === '' || !timeEnd || timeEnd === '' || timeStart.length < 5 || timeEnd.length < 5) return false;
  const [startHours, startMinutes] = timeStart.split(':');
  const [endHours, endMinutes] = timeEnd.split(':');

  const currStartDate = dayjs();
  const newStartDate = currStartDate.hour(Number(startHours)).minute(Number(startMinutes)).second(0).millisecond(0);
  const newEndDate = currStartDate.hour(Number(endHours)).minute(Number(endMinutes)).second(0).millisecond(0);

  const duration = dayjs.duration(newEndDate.diff(newStartDate));

  const hours = Math.floor(duration.asHours()); // Получаем количество часов
  const minutes = duration.minutes(); // Получаем оставшиеся минуты

  return { hours, minutes };
};

export const getTimeDurationStr = (timeStart: string | undefined, timeEnd: string | undefined, isSpace = false) => {
  if (!timeStart || timeStart === '' || !timeEnd || timeEnd === '') return '';
  const [startHours, startMinutes] = timeStart.split(':');
  const [endHours, endMinutes] = timeEnd.split(':');

  const currStartDate = dayjs();
  const newStartDate = currStartDate.hour(Number(startHours)).minute(Number(startMinutes)).second(0).millisecond(0);
  const newEndDate = currStartDate.hour(Number(endHours)).minute(Number(endMinutes)).second(0).millisecond(0);

  const duration = dayjs.duration(newEndDate.diff(newStartDate));

  const hours = Math.floor(duration.asHours()); // Получаем количество часов
  const minutes = duration.minutes(); // Получаем оставшиеся минуты

  return hours === 0 ? `${minutes}м` : minutes === 0 ? `${hours}ч` : `${hours}ч${isSpace ? ' ' : ''}${minutes}м`;
};

export const getTotalTimeDurationStr = (breaks: IBreak[] | undefined) => {
  if (!breaks) return '';
  let resultHours = 0;
  let resultMinutes = 0;

  if (breaks.length > 0) {
    breaks.forEach((breakItem) => {
      const [startHours, startMinutes] = breakItem.bstart.split(':');
      const [endHours, endMinutes] = breakItem.bend.split(':');
      const currStartDate = dayjs();
      const newStartDate = currStartDate.hour(Number(startHours)).minute(Number(startMinutes)).second(0).millisecond(0);
      const newEndDate = currStartDate.hour(Number(endHours)).minute(Number(endMinutes)).second(0).millisecond(0);
      const duration = dayjs.duration(newEndDate.diff(newStartDate));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      resultMinutes += minutes;
      resultHours += hours;
      if (resultMinutes >= 60) {
        const addHours = Math.floor(resultMinutes / 60);
        const addMinutes = resultMinutes % 60;
        resultHours += addHours;
        resultMinutes = addMinutes;
      }
    });
  }

  return resultHours === 0 ? `${resultMinutes}м` : resultMinutes === 0 ? `${resultHours}ч` : `${resultHours}ч${resultMinutes}м`;
};

const getDuration = (timeTable: any) => {
  let resultHours = 0;
  let resultMinutes = 0;

  if (!timeTable || !timeTable.timestart) return { resultHours, resultMinutes };

  const [startHours, startMinutes] = timeTable.timestart.split(':');
  const [endHours, endMinutes] = timeTable.timeend.split(':');
  const currStartDate = dayjs();
  const newStartDate = currStartDate.hour(Number(startHours)).minute(Number(startMinutes)).second(0).millisecond(0);
  const newEndDate = currStartDate.hour(Number(endHours)).minute(Number(endMinutes)).second(0).millisecond(0);
  const duration = dayjs.duration(newEndDate.diff(newStartDate));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  resultMinutes += minutes;
  resultHours += hours;

  return { resultHours, resultMinutes };
};

export const getFilialTimeDuration = (datesArr: string[], timeTableObj: any) => {
  if (!datesArr || datesArr.length === 0 || !timeTableObj) return '';
  let resultHours = 0;
  let resultMinutes = 0;

  datesArr.forEach((dateItem) => {
    const getWeekDay = dayjs(dateItem).locale('en').format('dddd').toLocaleLowerCase();
    const timeTable = timeTableObj[getWeekDay];
    if (timeTable.timestart === '') {
      return;
    }

    const { resultHours: hours, resultMinutes: minutes } = getDuration(timeTable);
    resultMinutes += minutes;
    resultHours += hours;
    if (resultMinutes >= 60) {
      const addHours = Math.floor(resultMinutes / 60);
      const addMinutes = resultMinutes % 60;
      resultHours += addHours;
      resultMinutes = addMinutes;
    }
  });

  return resultHours === 0 ? `${resultMinutes}м` : resultMinutes === 0 ? `${resultHours}ч` : `${resultHours}ч ${resultMinutes}м`;
};

export const getEmployeeTimeDuration = (datesArr: string[], timeTableArr: IEmployeeSchedule[] | undefined) => {
  if (!datesArr || datesArr.length === 0 || !timeTableArr || timeTableArr.length === 0) return '';
  let resultHours = 0;
  let resultMinutes = 0;

  datesArr.forEach((dateItem) => {
    const formattedDate = dayjs(dateItem).format('YYYY-MM-DD');
    const timeTable = timeTableArr.find((item) => item.date === formattedDate);

    if (!timeTable || timeTable.timestart === '' || timeTable.timeend === '') {
      return;
    }

    const { resultHours: hours, resultMinutes: minutes } = getDuration(timeTable);
    resultMinutes += minutes;
    resultHours += hours;
    if (resultMinutes >= 60) {
      const addHours = Math.floor(resultMinutes / 60);
      const addMinutes = resultMinutes % 60;
      resultHours += addHours;
      resultMinutes = addMinutes;
    }
  });

  return resultHours === 0 ? `${resultMinutes}м` : resultMinutes === 0 ? `${resultHours}ч` : `${resultHours}ч ${resultMinutes}м`;
};

// для группировки элементов массива по возрастанию
interface Item {
  [key: string]: any; // Дополнительные свойства объекта
}

export function groupConsecutiveIncreasingByProperty(arr: Item[], property: keyof Item): Item[][] {
  if (arr.length === 0) return [];

  const result: Item[][] = [];
  const sortedArr = [...arr].sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }

    return 0;
  });
  let currentGroup: Item[] = [sortedArr[0]];

  for (let i = 1; i < sortedArr.length; i++) {
    if (sortedArr[i][property] - sortedArr[i - 1][property] === 1) {
      currentGroup.push(sortedArr[i]);
    } else {
      result.push(currentGroup);
      currentGroup = [sortedArr[i]];
    }
  }

  result.push(currentGroup);

  return result;
}

export const getTransformedDuration = (duration: string | undefined) => {
  if (!duration) return '';
  const hours = Math.floor(+duration / 60);
  const minutes = +duration % 60;

  return hours > 0 ? (minutes > 0 ? `${hours}ч ${minutes}м` : `${hours}ч`) : `${minutes}м`;
};

export type GroupBy = 'day' | 'month';

export const transformDataWithDate = (dataArray: ISaleByDate[], groupBy: GroupBy = 'day'): IGroupedSales[] | [] => {
  if (!dataArray || dataArray.length === 0) {
    return [];
  }
  const groupedData = dataArray.reduce((acc: Record<string, ISaleByDate[]>, obj: ISaleByDate) => {
    const dateKey = groupBy === 'day' ? dayjs(obj.salesdate).format('YYYY-MM-DD') : dayjs(obj.salesdate).format('YYYY-MM');

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    acc[dateKey].push(obj);

    return acc;
  }, {});

  const resultArray: IGroupedSales[] = Object.keys(groupedData)
    .map((key) => ({
      date: key,
      transactions: groupedData[key]
    }))
    .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));

  return resultArray;
};

export const groupedByTypeTransactions = (dataArray: ISaleByDate[]): IGroupedTransactions[] | [] => {
  if (!dataArray || dataArray.length === 0) {
    return [];
  }
  const groupedData = dataArray.reduce((acc: Record<string, ISaleByDate[]>, obj: ISaleByDate) => {
    const formPayObj = obj.formpay && obj.formpay.length > 0 ? obj.formpay[0] : undefined;
    const product = obj.product;
    if (formPayObj && formPayObj.id) {
      if (!acc[formPayObj.id]) {
        acc[formPayObj.id] = [];
      }
      acc[formPayObj.id].push(obj);
    } else {
      switch (product) {
        case 'Пополнение кассы':
          if (!acc['Приход']) {
            acc['Приход'] = [];
          }
          acc['Приход'].push(obj);
          break;
        case 'Инкасация':
          if (!acc['Расход']) {
            acc['Расход'] = [];
          }
          acc['Расход'].push(obj);
          break;
        case 'Изъятие из кассы':
          if (!acc['Перевод из кассы']) {
            acc['Перевод из кассы'] = [];
          }
          acc['Перевод из кассы'].push(obj);
          break;
        default:
          if (!acc['Операция']) {
            acc['Операция'] = [];
          }
          acc['Операция'].push(obj);
          break;
      }
    }

    return acc;
  }, {});

  const resultArray: IGroupedTransactions[] = Object.keys(groupedData).map((key) => ({
    type: key,
    transactions: groupedData[key]
  }));

  return resultArray;
};

export function formatSumNumber(num: number) {
  const maxLimit = 100_000_000;

  if (num > maxLimit) {
    num = maxLimit;
  }

  return num.toLocaleString('ru-RU');
}
