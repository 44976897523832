import Slider from 'react-slick';

import styled from 'styled-components';

import themelight from '../../../app/providers/ThemeProvider/ui/theme';
import CommonButton from '../../../shared/ui/Button/ui/CommonButton';
import { DURATION, RADIUS_S, translateXIn, translateXOut, Z_INDEX } from '../../../styles';
import { FlexWithAlign } from '../../../utils/styleUtils';

export const ContentWrap = styled(FlexWithAlign)`
  // margin-top: 73px;
`;

export const ModalGrid = styled.div`
  display: grid;
  gap: 26px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  &.gap26 {
    gap: 26px;
  }
  &.gap60 {
    grid-template-columns: auto 1fr;
    align-items: center;
    > div {
      p {
        display: flex;
      }
    }
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
  &.classificator {
    grid-template-columns: 1fr 1fr 0.1fr;
    align-items: center;
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MainColumn = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: calc(100% - 1rem);
    margin-left: auto;
    margin-right: 0;
    height: 100%;
  }
  .sticky-block {
    width: 100%;
  }
  .container {
    padding: 0;
  }
`;

export const MainColumnBottom = styled(FlexWithAlign)`
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const SidebarWrapper = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.color.base01};
  border-radius: 0;
  transition-property: opacity, width, padding;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  opacity: 0;
  z-index: ${Z_INDEX};
  max-width: 218px;
  width: 100%;
  flex-shrink: 0;
  display: none;
  pointer-events: auto;
  border-right: 1px solid ${themelight.newColor.base08};

  // *,
  // *:before,
  // *:after {
  //   transition-property: visibility;
  //   transition-duration: ${DURATION};

  //   transition-timing-function: ease-in-out;
  //   visibility: hidden;
  //   opacity: 0;
  // }
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  // animation-name: ${translateXOut};

  &.open-sidebar {
    opacity: 1;
    display: block;
    animation-duration: ${DURATION};
    transition-duration: ${DURATION};
    transition-property: visibility;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    // animation-name: ${translateXIn};
    visibility: visible;
    // *,
    // *:before,
    // *:after {
    //   transition-property: visibility;
    //   transition-duration: ${DURATION};

    //   transition-timing-function: ease-in-out;
    //   visibility: visible;
    //   opacity: 1;
    // }
  }
`;
export const RecordingFixedContainer = styled(FlexWithAlign)`
  position: fixed;
  left: 56px;
  right: 0;
  top: 73px;
  background: ${(props) => props.theme.color.base01};
  z-index: 2;
  gap: 0;
  margin-bottom: 9px;
  // transition: transform 0.2s linear;
  // transform: translateY(0px) translateZ(0px);
  &.sticky {
    // transform: translateY(73px) translateZ(0px) !important;
    // margin-top: 73px;
    // transition: margin 0.3s ease;
  }
  @media (max-width: 767px) {
    padding-top: 5px;
    margin-bottom: 10px;
    &.sticky {
      left: 16px !important;
    }
  }
`;
export const TableRecordingHeader = styled(FlexWithAlign)`
  padding-left: 74px;
  // position: sticky;
  top: 0;
  height: 64px;

  z-index: 2;
  background: ${(props) => props.theme.color.base01};
  box-shadow: 0px 4px 8px 0px #312f331a;
  // transition: all 0.2s ease;
  @media (max-width: 767px) {
    border-top: none;
    margin-bottom: 20px;
    height: auto;
    transition: 0.3s ease-in-out;
  }
`;
export const TableRecordingDate = styled(FlexWithAlign)`
  width: 50px;
  min-width: 50px;
  &.hide {
    visibility: hidden;
  }
`;
export const WeekItem = styled(FlexWithAlign)`
  width: 100%;
  padding-bottom: 4px;
  background: ${(props) => props.theme.color.base01};
  cursor: pointer;
  border-radius: ${RADIUS_S};
  transition: background ${DURATION} ease-in-out;
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
`;

export const TodayBtn = styled(CommonButton)`
  position: fixed;
  bottom: 100px;
  right: 1rem;
  z-index: 9;
  &.center {
    transform: translateX(50%);
    right: 50%;
  }
  &.left {
    right: auto;
    left: 1rem;
  }
`;

export const DaySlider = styled(Slider)`
  padding: 0 !important;
  .slick-list,
  .slick-slider,
  .slick-track {
    padding: 0 !important;
  }
  max-width: calc(100% - 1rem);
  margin-bottom: 1rem;
  left: 0px;
  height: 50px;
  border-radius: 6px;
  position: relative;
  background: ${(props) => props.theme.color.base02};
  &.month {
    left: 0;
    margin: 0 auto 1rem;
    max-width: calc(100% - 2rem);
  }
`;

export const RecordingFixedHeaderContainer = styled(FlexWithAlign)`
  background-color: ${themelight.newColor.base01};
  z-index: 20;
  // position: fixed;
  // top: 0;
  // left: 56px;
  // width: calc(100% - 56px);
`;

export const RecordingFixedSideContainer = styled(FlexWithAlign)`
  background-color: ${themelight.newColor.base01};
  z-index: 20;
  &.sticky {
    margin-top: 73px;
  }
`;
