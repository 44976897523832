import { FC } from 'react';

import { ModalWrapper } from './PolicyModal.styled';

import RegistrationPolicy from '../../../../../pages/RegistrationPage/registration-policy/ui/RegistrationPolicy';

interface IProps {
  closeModal: () => void;
  modalPayload?: any;
}

const PolicyModal: FC<IProps> = ({ closeModal, modalPayload }) => {
  return (
    <ModalWrapper>
      <RegistrationPolicy
        policy={modalPayload?.policy}
        closeModal={closeModal}
      />
    </ModalWrapper>
  );
};

export default PolicyModal;
