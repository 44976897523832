// import { useMemo } from 'react';

// import { useFormik } from 'formik';

import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { CloseBtn, Form, Wrapper } from './NewSalesModal.styled';

import { capitalizeEveryWord } from '../../../../../shared/lib/helpers/regex.helper';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useCreateClient } from '../../../../../store/redux/clients/hooks/useClientsMutation';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { IService } from '../../../../../store/redux/subproducts/subproducts.interface';
import { MODAL_GAP } from '../../../../../styles';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { validationSaleClientAddSchema } from '../../../../../utils/validation-input';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

interface IProps {
  modalPayload: IService;
  closeModal: () => void;
}

const NewSalesModal = (props: IProps) => {
  const { modalPayload, closeModal } = props;
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const { data: activeFilial } = useFilialQuery();
  const { mutateAsync: createClientFn } = useCreateClient();

  const initialValues = useMemo(
    () => ({
      balance: 0,
      state: 21,
      birthday: '',
      filial: activeFilial?.name,
      telegram: false,
      viber: false,
      whatsapp: false,
      shouldValidatePhone: false,
      sex: false,
      phone: '',
      name: '',
      email: ''
    }),
    []
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSaleClientAddSchema,
    onSubmit: () => {},
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: false
  });

  const createClient = async (values: any) => {
    const name = capitalizeEveryWord(values.name);
    const res = await createClientFn({
      ...values,
      name: name
    });
    if (res?.status === 'ok') {
      closeModal();
      toast.success('Клиент успешно добавлен');
    } else {
      toast.error('Клиент не добавлен, произошла ошибка');
      toast.error(res.description);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email, ...newValues } = formik.values;
    const dataValues = email === '' ? newValues : formik.values;

    if (!formik.isValid || formik.values.name === '' || formik.values.phone === '') {
      if (formik.values.name === '') {
        setNameError(true);
      }
      if (formik.values.phone === '') {
        setPhoneError(true);
      }
    }

    createClient(dataValues);
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);
  useEffect(() => {
    if (phoneError) {
      formik.values.phone !== '' && setPhoneError(false);
    }
  }, [formik.values.phone]);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <FlexWithAlign
          className='form-wrapper'
          $column
        >
          <FlexWithAlign
            className='top-wrapper'
            $gap={'12px'}
            $align={'center'}
          >
            <CloseBtn onClick={closeModal}>
              <IconNew name={EIcon.blackclose} />
            </CloseBtn>
            <NewText
              $tag={TextType.H1}
              fontWeight={600}
              className='title'
            >
              Добавить клиента
            </NewText>
          </FlexWithAlign>
          <FlexWithAlign
            className='sale-container'
            $column
            $gap={MODAL_GAP}
          >
            <NewInput
              label='Номер телефона'
              name='phone'
              type={'tel'}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.phone && formik.errors.phone) || (phoneError && 'Введите телефон')}
            ></NewInput>
            <NewInput
              value={formik.values.name}
              onChange={formik.handleChange}
              name='name'
              onBlur={formik.handleBlur}
              error={formik.errors.name || (nameError && 'Введите Имя')}
              label='Имя клиента'
            ></NewInput>
            <NewInput
              label='Email'
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
              name='email'
            ></NewInput>
            <FlexWithAlign
              $gap={'16px'}
              $align={'center'}
              $justify={'flex-end'}
            >
              <NewButton
                className={'cancel'}
                onClick={closeModal}
              >
                Назад
              </NewButton>
              <NewButton
                classNameWrapper='save-w'
                className='save'
                typeBtn={'black'}
                type='submit'
                disabled={!formik.isValid}
              >
                Добавить
              </NewButton>
            </FlexWithAlign>
          </FlexWithAlign>
        </FlexWithAlign>
      </Form>
    </Wrapper>
  );
};

export default NewSalesModal;
