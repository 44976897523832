import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { INewPageHeader } from './NewPageHeader.props';
import { BackLink, NewPageHeaderWrapper, TitleWrap } from './NewPageHeader.styled';

import { IconNew as IconInstance, EIcon } from '../../../../components/icons/medium-new-icons/icon';
import NewSearchInput from '../../NewSearchInput/ui/NewSearchInput';
import NewText from '../../NewText/ui/NewText';
import { TextType } from '../../NewText/ui/NewText.props';

const NewPageHeader: FC<INewPageHeader> = (props) => {
  const { title, searchValue, onChange, searchPlaceholder, backLink, backIcon, children, isDefaultBackLink } = props;
  const nav = useNavigate();

  return (
    <NewPageHeaderWrapper>
      <TitleWrap>
        {backLink && (
          <BackLink
            className='back-link'
            to={backLink}
            onClick={(e) => {
              if (isDefaultBackLink) {
                e.preventDefault();
                nav(-1);
              }
            }}
          >
            {backIcon ? backIcon : <IconInstance name={EIcon.newBackArrow} />}
          </BackLink>
        )}
        <NewText
          $tag={TextType.H1}
          fontWeight={600}
        >
          {title}
        </NewText>
      </TitleWrap>
      {searchValue !== undefined && onChange ? (
        <NewSearchInput
          name={'search-page-header'}
          value={searchValue}
          onChange={onChange}
          placeholder={searchPlaceholder || 'Поиск...'}
          className={'search'}
        />
      ) : (
        children
      )}
    </NewPageHeaderWrapper>
  );
};

export default NewPageHeader;
