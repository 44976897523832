import { styled } from 'styled-components';

import themelight from '../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../utils/styleUtils';

export const Wrapper = styled(FlexWithAlign)``;

export const RadioBtnsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
`;

export const RadioBtnItem = styled.li``;

export const RadioBtnItemLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const InputWrap = styled(FlexWithAlign)`
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &:checked + span {
      box-shadow: 0 0 0 2px ${themelight.newColor.primary} inset;
      &::after {
        background-color: ${themelight.newColor.primary};
      }
    }
  }
`;

export const CustomRadioInput = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${themelight.newColor.base08} inset;
  transition: all 0.3s ease;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease;
  }
`;

export const CustomElementWrapper = styled(FlexWithAlign)``;
