import { FC, useEffect, useState } from 'react';

import { INewRadioBtns } from './NewRadioBtns.props';
import {
  CustomElementWrapper,
  CustomRadioInput,
  InputWrap,
  RadioBtnItem,
  RadioBtnItemLabel,
  RadioBtnsList,
  Wrapper
} from './NewRadioBtns.styled';

import { cn } from '../../lib';
import NewText from '../NewText/ui/NewText';

const NewRadioBtns: FC<INewRadioBtns> = ({ name, options, onChange, selectedValue, className, children }) => {
  const [currentValue, setCurrentValue] = useState<string>(selectedValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentValue(value);
    onChange(value);
  };
  useEffect(() => {
    if (selectedValue) {
      setCurrentValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Wrapper className={cn(className && `${className}`)}>
      <RadioBtnsList className='list'>
        {options.map((option) => (
          <RadioBtnItem
            key={option.value}
            className='item'
          >
            <RadioBtnItemLabel className='label'>
              <InputWrap>
                <input
                  type='radio'
                  name={name}
                  value={option.value}
                  checked={currentValue === option.value}
                  onChange={handleChange}
                />
                <CustomRadioInput className='custom-radio-input'></CustomRadioInput>
              </InputWrap>
              <NewText color='title'>{option.label}</NewText>
            </RadioBtnItemLabel>
            {option.element && <CustomElementWrapper>{option.element}</CustomElementWrapper>}
          </RadioBtnItem>
        ))}
      </RadioBtnsList>
    </Wrapper>
  );
};

export default NewRadioBtns;
