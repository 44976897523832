import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { getRouteCalendarEmployee } from '../../../../../shared/const';
import { cn } from '../../../../../shared/lib';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { Avatar, AvatarRounded, AvatarSize, ModalSize, ModalType } from '../../../../../shared/ui';
import { useMyBalanceQuery, useMyTarifQuery } from '../../../../../store/redux/tarif/hooks/useTarifQuery';
import { useProfileUserQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { getCookie } from '../../../../../utils/cookies';
import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import { useSubmenuData } from '../../lib/sidebar.data';
import { isLinkActive } from '../../lib/sidebar.helpers';
import {
  CollapsedButton,
  EndTab,
  Icon,
  MenuItem,
  MenuLink,
  MenuSubMenu,
  ProfileUserSide,
  SideAbsoluteCircle,
  SidebarContainer,
  SidebarTop,
  SideTooltip,
  UnreadMessages
} from '../Sidebar.styled';

const DesktopSide: FC = () => {
  const { setActiveDate, openModal, setIsCollapsed } = useActions();
  const { submenu } = useSubmenuData();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: activeTarif } = useMyTarifQuery();
  const { data: balance } = useMyBalanceQuery();
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const [tooltip, setTooltip] = useState<string | undefined>(undefined);

  const { userChats } = useTypedSelector((state) => state.chat);
  const { isCollapsed } = useTypedSelector((state) => state.sidebar);
  const { sale } = useTypedSelector((state) => state.sales);

  const unreadMessagesCount = userChats.map((chat) => chat.unread).reduce((total, unread) => total + unread, 0);
  const className =
    activeTarif &&
    dayjs(activeTarif.timeend).isBefore(dayjs()) &&
    balance &&
    balance <= 0 &&
    profileUser?.role === 'admin' &&
    'with-expire-tarif';

  const onToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClickOnProfile = () => {
    openModal({
      modalName: 'PROFILE_EXIT',
      modalSize: ModalSize.PROFILE_EXIT,
      modalType: ModalType.TOP
    });
  };
  const handleNavigation = (to: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    if (to === getRouteCalendarEmployee() && location.pathname !== getRouteCalendarEmployee()) {
      setActiveDate(new Date().toISOString());
    }
    //TODO доделать функцию для проверки полей sale для открытия модалки подтверждения отмены
    if (sale && sale?.saleproducts && sale?.saleproducts?.length > 0) {
      event.preventDefault();
      openModal({
        modalName: 'CONFIRM_NEW_DELETE',
        modalSize: ModalSize.CONFIRM_DELETE,
        modalType: ModalType.TOP,
        modalPayload: { sale },
        actionName: 'SALES',
        classModal: 'danger'
      });
    } else {
      navigate(to);
    }
  };

  return (
    <SidebarContainer className={cn(isCollapsed ? 'collapsed' : '', className ? className : '')}>
      <SidebarTop
        $gap={'9px'}
        $align={'center'}
      >
        <CollapsedButton onClick={onToggle}>
          <IconInstance name={EIcon.menu} />
        </CollapsedButton>
        <IconInstance name={EIcon.telebon} />
      </SidebarTop>

      <MenuSubMenu>
        {submenu.map(({ title, icon, to, endTab, tool }) => (
          <Fragment key={title}>
            <MenuItem
              onMouseEnter={() => setTooltip(title)}
              onMouseLeave={() => setTooltip(undefined)}
              $align={'center'}
              $gap={'8px'}
              className={cn(isLinkActive(to, location.pathname) && 'active', 'flex')}
            >
              {title === 'Продажи' && profileUser && profileUser.role === 'manager' ? null : (
                <MenuLink
                  // onClick={() =>
                  //   to === getRouteCalendarEmployee() &&
                  //   location.pathname !== getRouteCalendarEmployee() &&
                  //   setActiveDate(new Date().toISOString())
                  // }
                  onClick={(event) => handleNavigation(to, event)}
                  className='flex'
                  to={to}
                />
              )}
              <Icon className={cn(isLinkActive(to, location.pathname) && 'active')}>{icon}</Icon>
              {!isCollapsed ? (
                <p>{t(title)}</p>
              ) : (
                <SideTooltip className={cn(isCollapsed && tooltip === title && 'active')}>{t(tool)}</SideTooltip>
              )}
              {title == 'Чаты' && unreadMessagesCount > 0 ? <UnreadMessages>{unreadMessagesCount}</UnreadMessages> : ''}
            </MenuItem>
            {endTab && <EndTab />}
          </Fragment>
        ))}
      </MenuSubMenu>
      <ProfileUserSide onClick={handleClickOnProfile}>
        <Avatar
          background={'gray'}
          size={AvatarSize.X}
          rounded={AvatarRounded.FULL}
          avatar={profileUser?.avatar}
          title={profileUser?.fio}
        />
        <SideAbsoluteCircle>
          <Avatar
            background={'white'}
            size={AvatarSize.XXXS}
            rounded={AvatarRounded.FULL}
            title={profileUser?.fio}
          />
        </SideAbsoluteCircle>
      </ProfileUserSide>
    </SidebarContainer>
  );
};

export default DesktopSide;
