import { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { AddPositionBtn, BtnsWrap, DeletePositionBtn, Form, FormBlock, FormWrapper, TopWrapper } from './InputCashForm.styled';

import { useActions } from '../../../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useCashInput } from '../../../../../../../../store/redux/cash-register/hooks/useCashRegisterMutation';
import { useGetTransactionsHistory } from '../../../../../../../../store/redux/sales/hooks/useSalesQuery';
import { IconNew, EIcon } from '../../../../../../../icons/medium-new-icons/icon';

interface FormValues {
  [key: string]: string | number;
}

interface IProps {
  closeModal: () => void;
}

const InputCashForm: FC<IProps> = ({ closeModal }) => {
  const { mutateAsync: inputCash } = useCashInput();
  const { profileUser } = useTypedSelector((state) => state.user);
  const { currCashRegister } = useTypedSelector((state) => state.cashregister);
  const { setChosenTransactions } = useActions();
  const { data, refetch } = useGetTransactionsHistory({
    datestart: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    datefinish: dayjs().format('YYYY-MM-DD HH:mm:ss')
  });

  const [positionsArr, setPositionsArr] = useState<number[]>([0]);
  const { t } = useTranslation();
  const initialValues: FormValues = {
    comment0: '',
    responsible0: profileUser?.fio || '',
    price0: 0,
    comment1: '',
    responsible1: profileUser?.fio || '',
    price1: 0,
    comment2: '',
    responsible2: profileUser?.fio || '',
    price2: 0
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => handleInputCash(),
    enableReinitialize: true
  });

  const handleInputCash = async () => {
    const promises = positionsArr
      .filter((position) => Number(formik.values[`price${position}`]) > 0)
      .map((item) =>
        inputCash({
          kassa: currCashRegister?.id || '',
          comment: formik.values[`comment${item}`].toString(),
          summ: Number(formik.values[`price${item}`])
        })
      );
    const results = await Promise.all(promises);

    if (results.length > 0) {
      if (results.every((item) => item.status === 'ok')) {
        refetch();
        toast.success('Средства внесены в кассу');
        closeModal();
      } else {
        toast.error(results.find((item) => item.status !== 200)?.data.description || 'Ошибка');
      }
    }
  };
  useEffect(() => {
    if (data) {
      setChosenTransactions(data);
    }
  }, [data]);

  return (
    <FormWrapper
      $column
      $gap='30px'
    >
      <TopWrapper $align='center'>
        <NewText
          $tag={TextType.H3}
          color='title'
          fontWeight={600}
          $customSize={TextCustomType.T16}
        >
          {t('Позиции')}
        </NewText>
      </TopWrapper>
      <Form
        action='/'
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {positionsArr.map((position) => (
          <FormBlock key={position}>
            <NewInput
              name={`comment${position}`}
              value={formik.values[`comment${position}`] as string}
              onChange={formik.handleChange}
              label={'Назначение'}
            />
            <NewInput
              name={`responsible${position}`}
              value={formik.values[`responsible${position}`] as string}
              onChange={formik.handleChange}
              label={'Сотрудник'}
              disabled
            />
            <NewInput
              name={`price${position}`}
              value={formik.values[`price${position}`] as string}
              onChange={formik.handleChange}
              label={'Сумма'}
              type='currency'
            />
            <DeletePositionBtn
              type='button'
              typeBtn='only-icon'
              disabled={position === 0}
              onClick={() => {
                setPositionsArr((prevState) => [...prevState.filter((item) => item !== position)]);
              }}
            >
              <IconNew name={EIcon.deletebreak} />
            </DeletePositionBtn>
          </FormBlock>
        ))}
        <AddPositionBtn
          type='button'
          typeBtn='without-border'
          disabled={positionsArr.length >= 3}
          onClick={() => {
            setPositionsArr((prevState) => {
              if (prevState.includes(0)) {
                if (prevState.includes(1)) {
                  return [...prevState, 2];
                } else {
                  return [...prevState, 1];
                }
              } else {
                return [...prevState, 0];
              }
            });
          }}
        >
          <IconNew name={EIcon.pluschat} />
          <NewText color='title'>{t('Добавить позицию')}</NewText>
        </AddPositionBtn>
        <BtnsWrap
          $align='center'
          $justify='flex-end'
          $gap='16px'
        >
          <NewButton
            type='button'
            onClick={closeModal}
          >
            {t('Отмена')}
          </NewButton>
          <NewButton
            type='button'
            typeBtn='black'
            onClick={formik.handleSubmit}
            disabled={positionsArr.map((item) => formik.values[`price${item}`]).some((price) => Number(price) <= 0) || formik.isSubmitting}
          >
            {t('Сохранить')}
          </NewButton>
        </BtnsWrap>
      </Form>
    </FormWrapper>
  );
};

export default InputCashForm;
