import { useQuery } from '@tanstack/react-query';

import { SalesService } from '../../../../shared/api/model/service/sales.service';
import { SALES_QUERY, TRANSACTIONS } from '../../../../shared/const/query.const';
import { ISale, ISaleByDate, ISalesHistoryData } from '../sales.interface';

export function useSalesByDateQuery(date: string) {
  return useQuery<ISaleByDate[], Error>({
    queryKey: [SALES_QUERY, date],
    queryFn: () => SalesService.getSaleProductByDate(date),
    select: (data: ISaleByDate[]) => data || [],
    enabled: !!date
  });
}

// export function useSaleExpenses(value: ISale) {
//   return useQuery<ISale[], Error>({
//     queryKey: [SALES_QUERY, value],
//     queryFn: () => SalesService.postSaleExpenses(value),
//     select: (data: ISale[]) => data || [],
//     enabled: !!value})
// }

export function usePutSale(value: ISale) {
  return useQuery({
    queryKey: [SALES_QUERY, value],
    queryFn: () => SalesService.putSale(value),
    select: (data: ISale) => data || {},
    enabled: !!value
  });
}

export function useGetTransactionsHistory(data: ISalesHistoryData) {
  return useQuery({
    queryKey: [TRANSACTIONS, data],
    queryFn: () => SalesService.getTransactionsHistory(data),
    staleTime: 1800000000000,
    select: (data: ISaleByDate[]) => data || []
  });
}
