import { Area } from 'react-easy-crop';

export const getCroppedImg = (imageFile: File, croppedAreaPixels: Area): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      image.src = reader.result as string;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx?.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result as string); // Вернуть изображение в Base64
            };
          }
        }, 'image/jpeg');
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
