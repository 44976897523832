import {
  IPersonalSiteName,
  IPutRecordingPeriodAndSiteName,
  IRecordingPeriod
} from '../../../../store/redux/personalsite/personalsite.interface';
import { siteUrl } from '../../../../utils/api-constants';
import { apiGet, apiPost, apiPut } from '../../interceptors';

export const PersonalSiteService = {
  async fetchIdOnlineRecords() {
    const response = await apiGet('/idonlinerecords');

    return response.data.idonline;
  },
  async updateOnlineRecords(data: IPersonalSiteName) {
    const response = await apiPost('/updateonlinerecords', data);

    return response.data;
  },
  async fetchScheduleOnlineRecords(uniq: string) {
    const response = await apiGet(`${siteUrl}/config?uniq=${uniq}`);

    return response.data.error ? '' : response.data;
  },
  async createOnlineRecordsSchedule(values: IRecordingPeriod) {
    const response = await apiPost(`${siteUrl}/config`, values);

    return response.data;
  },

  async updateRecordingPeriod(values: IPutRecordingPeriodAndSiteName) {
    const response = await apiPut(`${siteUrl}/config?uniq=${values.uniq}`, { weeks: values.weeks });

    return response;
  },

  async updateSiteNameAndPeriod(values: IPutRecordingPeriodAndSiteName) {
    const response = await apiPut(`${siteUrl}/config?uniq=${values.olduniq}`, { uniq: values.uniq, weeks: values.weeks });

    return response;
  }
};
