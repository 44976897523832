export const IS_DEV = false;
export const apiBot = IS_DEV ? 'https://code10.ru/bot/telebot' : 'https://code10.ru/bot_prod/telebot';
export const apiServerBot = IS_DEV ? 'https://code10.ru/server_bot/telebot' : 'https://code10.ru/server_bot_prod/telebot';
export const apiUrl = IS_DEV ? 'https://dev.api.telebon.ru' : 'https://api.telebon.ru';
export const ID_SUPPORT = IS_DEV ? '6686be7821ca69fc50d76931' : '65b391618844d332a66311ad';

export const wsUrl = IS_DEV ? 'wss://code10.ru/messenger/ws1' : 'wss://code10.ru/messenger_prod/ws1';

export const siteUrl = IS_DEV ? 'https://dev.go.telebon.ru' : 'https://go.telebon.ru';
export const siteUrlText = IS_DEV ? 'dev.go.telebon.ru' : 'go.telebon.ru';
