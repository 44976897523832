type ClassObject = {
  [key: string]: boolean;
};

type TClassValue = string | number | boolean | null | undefined | ClassObject;

export const cn = (...args: TClassValue[]): string => {
  const classes: string[] = [];

  args.forEach((arg: TClassValue) => {
    if (Array.isArray(arg)) {
      classes.push(...arg.filter(Boolean).map(String));
    } else if (typeof arg === 'string') {
      classes.push(arg);
    } else if (typeof arg === 'object' && arg !== null) {
      Object.keys(arg).forEach((key) => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    }
  });

  return classes.join(' ');
};
