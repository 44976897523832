import styled from 'styled-components';

import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  width: 100%;
  height: 100%;
  min-height: 700px;
  background-color: ${themelight.newColor.black};
`;

export const ModalTopWrapper = styled(FlexWithAlign)`
  padding: 24px;
`;

export const CloseButton = styled(NewButton)`
  color: ${themelight.newColor.base01};
  background-color: transparent;
`;
export const CropWrapper = styled(FlexWithAlign)`
  margin: auto auto;
  width: 738px;
  height: 738px;
  background-color: ${themelight.newColor.base05};
  .reactEasyCrop_CropArea {
    border: none;
    color: rgba(0, 0, 0, 0.85);
  }
  .reactEasyCrop_CropAreaGrid::before,
  .reactEasyCrop_CropAreaGrid::after {
    border: 2px solid ${themelight.newColor.base05};
  }

  .reactEasyCrop_CropAreaGrid::after {
    border-left: none;
    border-right: none;
  }
  .reactEasyCrop_CropAreaGrid::before {
    border-top: none;
    border-bottom: none;
  }
`;

export const ModalBottomWrapper = styled(FlexWithAlign)`
  padding: 48px;
`;
