import styled from 'styled-components';

import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 15px 25px -6px #0000001a;
`;
export const Content = styled(FlexWithAlign)`
  flex-direction: column;
`;
export const TopWrapper = styled(FlexWithAlign)`
  gap: 14px;
  align-items: center;
`;

export const MainContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const MainContentLeft = styled(FlexWithAlign)`
  position: relative;
  padding: 0 24px;
  flex-direction: column;
  gap: 16px;
  border-right: 1px solid ${themelight.newColor.base08};
  .chosen-timetable-title {
    text-align: center;
  }
`;

export const MainContentRight = styled(FlexWithAlign)`
  padding: 0 38px;
  flex-direction: column;
  gap: 16px;
  min-height: 370px;
  max-height: 370px;
  overflow: hidden;

  .apply-timetable-title {
    text-align: center;
  }
`;

export const ChosenWeek = styled.div`
  padding: 10px 16px;
  min-height: 46px;
  border-radius: 5px;
  border: 1px solid ${themelight.newColor.base08};
`;

export const ChooseWeekList = styled.ul`
  padding: 0 6px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;

export const ChooseWeekItem = styled.li`
  padding-bottom: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const EmployeesContentWrapper = styled(FlexWithAlign)`
  flex-direction: column;
  gap: 12px;
`;

export const EmployeesContent = styled(FlexWithAlign)`
  padding: 24px 0 20px 0;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: ${themelight.newColor.base08};
`;

export const EmployeesList = styled.ul`
  padding: 0 28px;
  display: flex;
  align-items: center;
  gap: 64px;
  overflow-x: auto;
  max-width: 100%;
`;

export const EmployeesItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;
export const ButtonsWrapper = styled(FlexWithAlign)`
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const LabelArrow = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${themelight.newColor.base08};
  border-radius: 50%;
  background-color: ${themelight.newColor.base01};
`;
