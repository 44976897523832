import { StatusType } from './chat-slice';

import { wsUrl } from '../../../utils/api-constants';
import { getCookie } from '../../../utils/cookies';

const subscripers = {
  'messages-received': [] as MessagesReceivedSubscriberType[],
  'status-changed': [] as StatusChangedSubscriberType[]
};

type MessagesReceivedSubscriberType = (messages: ChatMessageType) => void;
type StatusChangedSubscriberType = (status: StatusType) => void;

let ws: WebSocket | null;

const notifySubscribersAboutStatus = (status: StatusType) => {
  subscripers['status-changed'].forEach((s) => s(status));
};

const closeHandler = () => {
  notifySubscribersAboutStatus('pending');
  setTimeout(createChannel, 3000);
};

const messageHandler = (e: MessageEvent) => {
  const newMessages = JSON.parse(e.data);
  subscripers['messages-received'].forEach((s) => s(newMessages));
};

const openHandler = () => {
  notifySubscribersAboutStatus('ready');
};

const errorHandler = () => {
  notifySubscribersAboutStatus('error');
};

const cleanUp = () => {
  ws?.removeEventListener('close', closeHandler);
  ws?.removeEventListener('message', messageHandler);
  ws?.removeEventListener('open', openHandler);
  ws?.removeEventListener('error', errorHandler);
};

function createChannel(userFio: string) {
  cleanUp();
  ws?.close();
  ws = new WebSocket(`${wsUrl}?userId=${getCookie('id')}&userFio=${userFio}`);

  notifySubscribersAboutStatus('pending');
  ws.addEventListener('close', closeHandler);
  ws.addEventListener('message', messageHandler);
  ws.addEventListener('open', openHandler);
  ws.addEventListener('error', errorHandler);
}
export const chatAPI = {
  start(userFio: string) {
    createChannel(userFio);
  },
  stop() {
    subscripers['messages-received'] = [];
    subscripers['status-changed'] = [];
    cleanUp();
    ws?.close();
  },
  subscribe(eventName: EventsNamesType, callback: MessagesReceivedSubscriberType | StatusChangedSubscriberType) {
    //@ts-ignore
    subscripers[eventName].push(callback);

    return () => {
      //@ts-ignore
      subscripers[eventName] = subscripers[eventName].filter((s) => s !== callback);
    };
  },
  unsubscribe(eventName: EventsNamesType, callback: MessagesReceivedSubscriberType | StatusChangedSubscriberType) {
    //@ts-ignore
    subscripers[eventName] = subscripers[eventName].filter((s) => s !== callback);
  },
  sendMessage(message: string) {
    if (ws?.readyState === WebSocket.OPEN) {
      ws?.send(message);
    }
  }
};

export type IChatListData = {
  chat_id: string;
  chat_name: string;
  created_at: string;
  group_admin: string;
  unread: number;
  isGroupChat: boolean;
  is_tech_support: boolean;
  lastest_message: string | null;
  updated_at: string;
  users: [{ user_id: string }];
};

export type IChatMessageData = {
  id: string;
  content: string;
  created_at: string;
  sender: string;
  updated_at: string;
  chat: IChatListData;
};

export type ChatMessageType = {
  data: IChatListData[] | IChatMessageData[];
  type: string;
};

export type EventsNamesType = 'messages-received' | 'status-changed';
