import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchLessonsAsync } from './lessons.actions';
import { ILesson, LessonsState } from './lessons.interface';

import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';

const initialState: LessonsState = {
  lessons: [],
  forPaymentLesson: null,
  state: PENDING
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    setForPaymentLesson: (state, action: PayloadAction<ILesson | null>) => {
      state.forPaymentLesson = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchLessonsAsync.fulfilled, (state, action) => {
        state.lessons = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchLessonsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer, actions } = lessonsSlice;
export const { setForPaymentLesson } = actions;
