import { styled } from 'styled-components';

import themelight from '../../../../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  margin-top: 24px;
  background-color: ${themelight.newColor.base01};
  padding: 24px;
  min-width: 389px;
  height: fit-content;
  box-shadow: 0px 15px 25px -6px #0000001a;
  border-radius: 8px;
`;

export const TopWrapper = styled(FlexWithAlign)`
  width: 100%;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const BtnsWrap = styled(FlexWithAlign)`
  padding: 4px 12px 0 12px;
`;
