import { styled } from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { NEW_INPUT_BORDER_RADIUS, NEW_INPUT_FONT_SIZE } from '../../../../styles/newAssets';

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2.625rem;
  min-width: 140px;
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  input::-moz-placeholder {
    color: transparent;
  }
`;

export const SearchInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  background-color: ${themelight.newColor.base01};
  inset: 0;
  margin: 0;
  border: 1px solid ${themelight.newColor.base08};
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  font-size: ${NEW_INPUT_FONT_SIZE};
  color: ${themelight.newColor.primary};
  transition: all 0.2s ease;
  // &::-webkit-input-placeholder {
  //   padding-left: 35px;
  // }
  &::placeholder {
    padding-left: 35px;
    color: ${themelight.newText.textGrey};
  }
  &:placeholder-shown::before {
    content: attr(placeholder);
    position: absolute;
    top: 8px;
    left: 35px;
    color: ${themelight.newText.textGrey};
    pointer-events: none;
    transition: opacity 0.2s ease;
    opacity: 1;
  }
  // &::-moz-placeholder {
  //   opacity: 1;
  // }

  // &:-ms-input-placeholder {
  //   color: transparent;
  // }

  // &::-ms-input-placeholder {
  //   color: transparent;
  // }
  &:focus + .placeholder,
  &:not(:placeholder-shown) + .placeholder {
    opacity: 0;
  }
  &:focus {
    border-color: ${themelight.newColor.primary};
    box-shadow: ${themelight.newStyles.searchInputBoxShadow};
    &::placeholder {
      opacity: 0;
    }
  }
  &.greyInput {
    background-color: ${themelight.color.greyBackground};
    height: 42px;
  }
`;

export const StyledPlaceholder = styled.span`
  position: absolute;
  top: 50%;
  left: 51px;
  transform: translateY(-50%);
  font-size: ${NEW_INPUT_FONT_SIZE};
  color: ${themelight.newText.textGrey};
  pointer-events: none;
  transition: opacity 0.2s ease;
  opacity: 1;
`;

export const SearchIconBtn = styled.button`
  display: flex;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: ${themelight.newText.textGrey};
`;
