import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Form, TarifTopbar, TimeEndWrap, Total, Wrapper } from './ChangeTarifModal.styled';

import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { Input } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { validationTarifModal } from '../../../../../utils/validation-input';

interface IProps {
  modalPayload?: any;
  edit?: boolean;
}

const ChangeTarifModal: React.FC<IProps> = (props) => {
  const { modalPayload, edit } = props;
  const { id, name, users, price, dynamicadd } = modalPayload;
  const { activeTarif } = useTypedSelector((state) => state.tarif);
  const { closeModal, setMyTarifAsync } = useActions();
  const [totalPrice, setTotalPrice] = useState(Number(price));

  const { t } = useTranslation();

  const nav = useNavigate();

  const initialValues = {
    countUsers: 0
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    validationSchema: validationTarifModal,
    validateOnChange: true
  });

  const getTotalPrice = () => {
    const dateTarifStart = dayjs(activeTarif.timeend).subtract(1, 'month');
    const validity = dayjs(activeTarif.timeend).diff(dateTarifStart, 'day');
    const remainingDays = Math.trunc(dayjs(activeTarif.timeend).diff(dayjs(), 'day', true)) + 1;
    const costDay = dynamicadd[0].price / validity;
    const costRemainingDays = costDay * remainingDays;
    if (activeTarif.name === 'Standart') {
      setTotalPrice(Math.trunc(Number(formik.values.countUsers) * costRemainingDays));
    } else {
      setTotalPrice(formik.values.countUsers * dynamicadd[0].price);
    }
  };
  useEffect(() => {
    if (formik.isValid) {
      getTotalPrice();
    } else {
      setTotalPrice(0);
    }
  }, [formik.values, formik.isValid]);
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const iddynamicArray = [];
    if (formik.values.countUsers > 0) {
      for (let i = 0; i < formik.values.countUsers; i++) {
        iddynamicArray.push({
          iddynamic: dynamicadd[0].id
        });
      }

      setMyTarifAsync({
        action: 'web',
        summ: totalPrice.toString()
      });

      nav('/portalsettings/redirectpaymentpage');
      closeModal();
    }
  };

  return (
    <Wrapper>
      <TarifTopbar>
        <h2>{t(`Тариф «${name}»`)}</h2>
        {edit && (
          <TimeEndWrap className='flex'>
            <span>{t('Активен до:')}</span> <span>{dayjs(activeTarif.timeend).format('DD.MM.YYYY')}</span>
          </TimeEndWrap>
        )}
      </TarifTopbar>
      <div className='current-tarif flex'>
        <span className='label'>{t('Текущий тариф:')}</span>
        <span className='value'>{t(activeTarif.name)}</span>
      </div>
      <div className='users flex'>
        <span className='label'>{t('Подключено пользователей:')} </span>
        <span className='value'>{t(`${activeTarif.users}`)}</span>
      </div>
      <div className='flex payment-methods'>
        <span className='label'>{t('Способ оплаты:')} </span>
        <span className='value'>{t('Банковская карта')}</span>
      </div>

      <Form onSubmit={handleSubmit}>
        <Input
          type='number'
          name='countUsers'
          value={formik.values.countUsers}
          label={'Добавьте кол-во пользователей'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          min={0}
          error={formik.touched.countUsers && formik.errors.countUsers}
        />
        <Total className='flex'>
          <span className='label'>{t('Итого к оплате:')} </span>
          <span className='value'>
            {t(
              `${new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB'
              }).format(totalPrice)}`
            )}
          </span>
        </Total>

        <CommonButton
          typeBtn='primary'
          className='tarif-payment-btn'
          disabled={totalPrice === 0 || !formik.isValid}
        >
          {t('Перейти к оплате')}
        </CommonButton>
      </Form>
    </Wrapper>
  );
};

export default ChangeTarifModal;
