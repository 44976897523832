import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { iconData } from './CashRegisterIconModal.data';
import { IconItem, IconItemBtn, IconsList, Wrapper } from './CashRegisterIconModal.styled';

import { CashRegisterService } from '../../../../../../shared/api/model/service/cashregister.service';
import { cn } from '../../../../../../shared/lib';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { ICashRegisterData } from '../../../../../../store/redux/cash-register/cashRegister.interface';
import { useCashRegisterUpdate } from '../../../../../../store/redux/cash-register/hooks/useCashRegisterMutation';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { getIconByFinanceIcon } from '../../../../../icons/helper-functions/payments-settings.helper';
import { EIcon, IconNew } from '../../../../../icons/medium-new-icons/icon';

interface IProps {
  closeModal: () => void;
  cashRegister?: ICashRegisterData;
}

const CashRegisterIconModal: FC<IProps> = ({ closeModal, cashRegister }) => {
  const { mutateAsync: updateCashRegister } = useCashRegisterUpdate();

  const { chosenCashRegisterIcon } = useTypedSelector((state) => state.cashregister);
  const { setChosenCashRegisterIcon, setActiveCashRegister } = useActions();
  const { t } = useTranslation();

  const handleUpdateIcon = async (icon: string) => {
    if (!cashRegister) return;
    const res = await updateCashRegister({ ...cashRegister, icon: icon });
    if (res.status === 'ok') {
      toast.success('Иконка кассы изменена');
      const resCasgRegister = await CashRegisterService.fetchCashRegisterById(cashRegister.id);
      if (resCasgRegister.data) {
        setActiveCashRegister(resCasgRegister.data.kassa || null);
      }
      closeModal();
    } else {
      toast.error('Произошла ошибка при обновлении');
    }
  };

  return (
    <Wrapper
      $column
      $gap='40px'
    >
      <FlexWithAlign
        $gap='16px'
        $align='center'
      >
        <NewButton
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H2}
          color='title'
          fontWeight={600}
        >
          {t('Редактирование иконки')}
        </NewText>
      </FlexWithAlign>
      <IconsList>
        {iconData.map((icon) => (
          <IconItem key={icon.name}>
            <IconItemBtn
              onClick={() => {
                if (cashRegister) {
                  handleUpdateIcon(icon.name);
                }
                setChosenCashRegisterIcon(icon.name);
              }}
              className={cn(icon.name === chosenCashRegisterIcon && 'active')}
            >
              {getIconByFinanceIcon(icon.name)}
            </IconItemBtn>
          </IconItem>
        ))}
      </IconsList>
    </Wrapper>
  );
};

export default CashRegisterIconModal;
