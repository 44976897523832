import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import { ICreateClientRecordingModal } from './CreateClientRecordingModal.props';
import { BtnsWrap, FormWrapper, ModalWrapper, TopWrapper } from './CreateClientRecordingModal.styled';

import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { IClient } from '../../../../../../store/redux/clients/clients.interface';
import { useCreateClient } from '../../../../../../store/redux/clients/hooks/useClientsMutation';
import { useClientsQuery } from '../../../../../../store/redux/clients/hooks/useClientsQuery';
import { useFilialQuery } from '../../../../../../store/redux/filial/hooks/useFilialQuery';
import { validationClientModalAddSchema } from '../../../../../../utils/validation-input';
import { IconNew, EIcon } from '../../../../../icons/medium-new-icons/icon';

const CreateClientRecordingModal: FC<ICreateClientRecordingModal> = ({ closeModal, modalPayload }) => {
  const { data: filial } = useFilialQuery();
  const { formik: mainFormik } = modalPayload;
  const { mutateAsync: createClient } = useCreateClient();
  const queryClient = useQueryClient();
  const { data: clients, refetch, isRefetching } = useClientsQuery();
  const { setChatSub, setActiveClient } = useActions();
  const { t } = useTranslation();
  const initialValues = {
    phone: '',
    name: '',
    email: ''
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any, { setSubmitting }) => {
      const { email, ...newValues } = values;
      const resValues = email && email !== '' ? values : newValues;
      const res = await createClient({
        ...resValues,
        comstart: 0,
        comfinish: 0,
        telegram: false,
        viber: false,
        whatsapp: false,
        state: 21,
        filial: filial ? filial.id : '',
        comment: '',
        sex: false,
        birthday: '10.10.2000',
        commethod: 1,
        dopphone: ''
      });
      if (res?.id) {
        await queryClient.invalidateQueries({ queryKey: ['clients'] });
        const updatedClients: IClient[] | undefined = queryClient.getQueryData(['clients']);

        const newClient = updatedClients?.find((client: any) => client.id === res.id);

        if (mainFormik) {
          mainFormik.setFieldValue('clientId', [{ idclient: res?.id, pays: 0, confirmation: 1 }]);
        }
        if (newClient) {
          setActiveClient(newClient);
        }

        setChatSub('main');
        closeModal();
      }
      setSubmitting(false);
    },
    validationSchema: validationClientModalAddSchema,
    validateOnBlur: true
  });
  const handleCreate = () => {
    formik.handleSubmit();
  };

  return (
    <ModalWrapper $column>
      <TopWrapper
        $align='center'
        $gap='8px'
      >
        <NewButton
          type='button'
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H2}
          $customSize={TextCustomType.T28}
          color='title'
          fontWeight={700}
        >
          {t('Добавление клиента')}
        </NewText>
      </TopWrapper>
      <FormWrapper>
        <NewInput
          name='phone'
          type={'tel'}
          value={formik.values.phone}
          onChange={formik.handleChange}
          label={'Номер телефона'}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && formik.errors.phone}
        />
        <NewInput
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          label={'Имя клиента'}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors.name}
        />
        <NewInput
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          label={'Email'}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />
        <BtnsWrap
          $gap='16px'
          $align='center'
          $justify='flex-end'
        >
          <NewButton
            type='button'
            onClick={closeModal}
          >
            {t('Отмена')}
          </NewButton>
          <NewButton
            typeBtn='black'
            onClick={handleCreate}
            // disabled={!formik.isValid}
          >
            {t('Добавить')}
          </NewButton>
        </BtnsWrap>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default CreateClientRecordingModal;
