import { useQuery } from '@tanstack/react-query';

import { ILesson } from './lessons.interface';

import { LessonsService } from '../../../shared/api/model/service/lessons.service';
import dayjs from 'dayjs';

export function useLessonsQuery() {
  return useQuery<ILesson[], Error>({
    queryKey: ['lessons'],
    staleTime: 1800000000000,
    queryFn: () => LessonsService.fetchLessons(),
    select: (data: ILesson[] | undefined) => data || []
  });
}

export function useLessonQuery(lessonId: string) {
  return useQuery<ILesson[], Error>({
    queryKey: [`lessons_${lessonId}`],
    queryFn: () => LessonsService.fetchLesson(lessonId),
    select: (data: ILesson[] | undefined) => data || []
  });
}

export function useLessonsLastMonthQuery() {
  return useQuery<ILesson[], Error>({
    queryKey: ['lessons_last_month'],
    queryFn: () => LessonsService.fetchLessons(),
    select: (data: ILesson[] | undefined) => data?.filter((item) => dayjs(item.end).isAfter(dayjs().subtract(1, 'month'))) || []
  });
}

export function useLessonsClientQuery(id: string) {
  return useQuery<ILesson[], Error>({
    queryKey: ['lessons_last_month'],
    queryFn: () => LessonsService.fetchLessons(),
    enabled: !!id,
    select: (data: ILesson[] | undefined) =>
      data?.filter((lesson) => lesson?.ClientId && lesson?.ClientId.find((item) => item.idclient === id)) || []
  });
}
