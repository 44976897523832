import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  height: fit-content;
`;

export const Btn = styled.button`
  padding: 12px 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  min-height: 48px;
  border: 1px solid ${(props) => props.theme.newColor.ghost};
  border-radius: 8px;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 110px;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.newColor.base01};
  color: ${(props) => props.theme.newColor.primary};
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 7px;
    flex-shrink: 0;
    min-width: 48px;
  }
  &.only-icon-black {
    padding: 7px;
    flex-shrink: 0;
    min-width: 48px;
    background-color: ${(props) => props.theme.newColor.primary};
    border-color: ${(props) => props.theme.newColor.ghost};
  }
  &.circle-white {
    padding: 6px;
    border-radius: 50%;
    min-width: 48px;
    box-shadow: ${(props) => props.theme.newStyles.btnBoxShadow};
    border: none;
  }
  &.circle-black {
    padding: 6px;
    border-radius: 50%;
    min-width: 48px;
    box-shadow: ${(props) => props.theme.newStyles.btnBoxShadow};
    border: none;
    background-color: ${(props) => props.theme.newColor.primary};
    color: ${(props) => props.theme.newColor.base01};
  }
  &.black {
    background-color: ${(props) => props.theme.newColor.primary};
    color: ${(props) => props.theme.newColor.base01};
    border-color: ${(props) => props.theme.newColor.ghost};
    &:disabled {
      background-color: ${(props) => props.theme.newColor.grey01};
    }
  }
  &.without-border {
    padding: 13px 0px 13px 13px;
    border: none;
    text-transform: initial;
    color: ${(props) => props.theme.newText.textMain};
    flex-shrink: 0;
    background-color: transparent;
    font-weight: 400;
    &:disabled {
      background-color: transparent;
      color: ${(props) => props.theme.newColor.grey01};
      span {
        color: ${(props) => props.theme.newColor.grey01};
      }
    }
  }
  &.red {
    color: ${(props) => props.theme.newColor.red};
    &:disabled {
      color: ${(props) => props.theme.newColor.grey08}};
      border-color: ${(props) => props.theme.newColor.ghost};
      background-color: ${(props) => props.theme.newColor.base01};
    }
  }
  &.close {
    padding: 0;
    border: none;
    min-height: 36px;
    min-width: 36px;
    svg {
      width: 100%;
      height: 100%;
      max-height: 36px;
      max-width: 36px;
    }
  }
  &:disabled {
    color: ${(props) => props.theme.newColor.base01};
    background-color: ${(props) => props.theme.newColor.grey01};
    border-color: ${(props) => props.theme.newColor.base08};
    cursor: default;
  }
`;
