import { FC, useRef, useState } from 'react';

import { INewSearchInput } from './NewSearchInput.props';
import { SearchIconBtn, SearchInput, SearchInputWrapper, StyledPlaceholder } from './NewSearchInput.styled';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';

const NewSearchInput: FC<INewSearchInput> = (props) => {
  const { name, placeholder, onChange, value, className } = props;
  const refInput = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleButtonIconClick = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <SearchInputWrapper className={cn(className && `${className}`)}>
      {!isFocused && value === '' && (
        <SearchIconBtn
          type='button'
          onClick={handleButtonIconClick}
        >
          <IconNew name={EIcon.search} />
        </SearchIconBtn>
      )}

      <SearchInput
        ref={refInput}
        type='text'
        value={value}
        className={className}
        placeholder={''}
        name={name}
        id={name}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete={'off'}
      />
      <StyledPlaceholder className='placeholder'>{placeholder ? placeholder : 'Найти...'}</StyledPlaceholder>
    </SearchInputWrapper>
  );
};

export default NewSearchInput;
