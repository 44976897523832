import { ForwardedRef, ReactNode } from 'react';

export enum ModalSize {
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
  NewXXS = 'newxxs',
  RECORDING = 'recording',
  PUSH = 'push',
  IMPORT_CLIENTS = 'import-clients',
  TIME_TABLE = 'time-table',
  MESSENGER_EDITOR = 'messenger-editor',
  NOTE_CREATOR = 'note-creator',
  REGISTRATION_TIME_TABLE = 'register-time-table',
  CATEGORY_CREATOR = 'category-creator',
  CONFIRM_DELETE = 'confirm-delete',
  TELEGRAM_BOT = 'telegram-bot',
  NEW_SALES = 'new-sales',
  PROFILE_EXIT = 'profile-exit',
  CASH_REGISTER_ICON = 'cash-register-icon',
  FULL = 'full'
}
export enum ModalType {
  CENTER = 'center',
  SIDE = 'side',
  SIDE_OVERLAY = 'side-overlay',
  SIDE_OVERLAY_TRANSPARENT = 'side-overlay-transparent',
  TOP = 'top'
}

export interface ModalProps {
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  lazy?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
  size?: ModalSize;
  type?: ModalType;
  btnColor?: string;
  isInsideCloseButton?: boolean;
}
