import React, { FC } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

import { CloseButton, List, RegistrationPolicyWrapper, TopWrapper } from './RegistrationPolicy.styled';

import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import NewButton from '../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../shared/new-ui/NewText/ui/NewText.props';
import { FlexWithAlign, Padding } from '../../../../utils/styleUtils';

interface IProps {
  policy: boolean;
  closeModal: () => void;
}

const RegistrationPolicy: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { policy, closeModal } = props;

  return (
    <RegistrationPolicyWrapper $column>
      <TopWrapper
        $gap='16px'
        $align='center'
      >
        <CloseButton onClick={closeModal}>
          <IconInstance name={EIcon.grayx} />
        </CloseButton>

        <NewText
          $customSize={TextCustomType.T28}
          fontWeight={600}
          color={'title'}
        >
          {policy ? t('Политика конфиденциальности') : t('Лицензионный договор')}
        </NewText>
      </TopWrapper>
      <FlexWithAlign>
        {policy ? (
          <List>
            <Padding $size='56px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ООО «ГРУППА КОМПАНИЙ «БЕЛЫЙ МЕДВЕДЬ»')}
            </NewText>
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              {t('Редакция 2023.12.10 от «10» Декабря 2023')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('1. Общие положения')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              1.1. Политика в отношении обработки персональных данных ООО «Группа компаний «Белый Медведь» (далее — «Политика») определяет
              основные цели, принципы и условия обработки персональных данных, обязанности ООО «Группа компаний «Белый Медведь» (далее —
              Общество, Компания) при обработке персональных данных, права субъектов персональных данных, а также применяемые в ООО «Группа
              компаний «Белый Медведь» меры по защите персональных данных. <br />
              <br />
              1.2. Политика разработана в соответствии с требованиями законодательства в области обработки и защиты персональных данных,
              основанного на Конституции Российской Федерации и состоящего из Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных
              данных», иных федеральных законах и подзаконных нормативных правовых актах, регулирующих вопросы обработки и защиты
              персональных данных. <br />
              <br />
              1.3. Политика регулирует отношения между Общестом и: • физическим лицом (соискателем, работником Оператора, клиентом,
              представителем клиента, контрагентом, иным субъектом) по обработке и защите персональных данных; • физическим лицом,
              являющимся пользователем сайта Оператора и (или) их поддоменов, находящихся по адресам в сети Интернет: https://telebon.ru/,
              по обработке и защите персональных данных, получаемых и обрабатываемых с использованием сайтов. <br />
              <br />
              1.4. В целях реализации положений Политики ООО «Группа компаний «Белый Медведь» разрабатываются локальные нормативные акты и
              иные документы, регламентирующие вопросы обработки персональных данных в Обществе.
              <br />
              <br />
              1.5. Общество периодически актуализирует настоящую Политику и вправе в одностороннем порядке в любой момент времени изменять
              ее положения. Общество рекомендует регулярно проверять содержание настоящей Политики на предмет ее возможных изменений.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('2. Термины и определения')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              2.1. Термины и определения, содержащиеся в настоящей Политике, используются в следующем значении. Персональные данные — любая
              информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных)
              Персональные данные, разрешенные субъектом персональных данных для распространения — персональные данные, доступ
              неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных
              данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Федеральным законом от
              27.07.2006 №152 ФЗ «О персональных данных» Обработка персональных данных — любое действие (операция) или совокупность действий
              (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка
              персональных данных включает в себя, в том числе: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
              удаление, уничтожение. Автоматизированная обработка персональных данных — обработка персональных данных (далее-ПДн) с помощью
              средств вычислительной техники (далее — СВТ) Биометрические персональные данные — сведения, которые характеризуют
              физиологические и биологические особенности человека, на основании которых можно установить его
              личностьикоторыеиспользуютсяоператоромдляустановления личностисубъекта персональных данных Блокирование персональных данных —
              Временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения
              персональных данных) Информация — сведения (сообщения, данные) независимо от формы их представления Информационная система
              персональных данных (ИСПДН) — Совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку
              информационных технологий и технических средств Обезличивание персональных данных — Действия, в результате которых невозможно
              определить без использования дополнительной информации принадлежность персональных данных конкретному субъекту персональных
              данных Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с
              использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных Оператора персональных данных
              (Оператор) — Общество с ограниченной ответственностью «Группа компаний «Белый Медведь» (далее по тексту —, Общество,
              Организация, Компания) — лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
              персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих
              обработке, действия (операции), совершаемые с персональными данными; Субъекты персональных данных (Субъект) — физическое лицо
              (в том числе, но не исключительно, работники, в связи с трудовыми отношениями с Организацией, кандидаты на заключение
              трудового договора с Организацией, иные физические лица или представители юридического лица, заключившие или планирующие
              заключение с Организацией договоры в соответствии с гражданским законодательством, физические лица, входящие в органы
              управления Организации, их представители, физические лица, являющиеся Участниками Организации, посетители сайта), персональные
              данные которых обрабатываются Оператором. Категории персональных данных — общие персональные данные (базовые личные данные),
              специальные персональные данные (информация о личности человека), биометрические персональные данные (физиологические или
              биологические особенности человека, которые используют для установления его личности), иные персональные данные).
              Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или
              определенному кругу лиц Распространение персональных данных — действия, направленные на раскрытие персональных данных
              неопределенному кругу лиц Трансграничная передача персональных данных — передача персональных данных на территорию
              иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу,
              обеспечивающих адекватную защиту прав субъектов персональных данных Уничтожение персональных данных — действия, в результате
              которых невозможно восстановить содержание персональных данных в информационной системе персональных данных Файлы cookies —
              текстовой файл небольшого размера, который направляется веб-сервером и сохраняется на компьютере, мобильном телефоне или любом
              другом устройстве, имеющем доступ в сеть Интернет, при посещении веб-сайта или мобильного приложения. Аффилированные лица —
              связанные между собой взаимным контролем. Связь может быть обусловлена долевым владением, договорами, а также участием в
              организациях с одним руководством.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3. Принципы, цели, категории субъектов персональных данных и правовые основания обработки персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              3.1. Общество осуществляет обработку персональных данных руководствуясь принципами, изложенными в статье 5 Федерального закона
              от 27 июля 2006 г. №152-ФЗ «О персональных данных». <br />
              <br />
              3.2. Общество осуществляет обработку персональных данных субъектов в целях, изложенных ниже. Конкретные цели обработки
              устанавливаются в согласии на обработку персональных, которое дает субъект. Подбор кадров, кадровое делопроизводство —
              Взаимодействие с работниками Компании Кандидаты на вакантные позиции — Подбор и согласование персонала на вакантные должности
              Работники Компании — Управление персоналом и кадровое администрирование, включая ведение кадрового и бухгалтерского учета,
              составление налоговой отчетности, обеспечение соблюдения требований законодательства, оформление и регулирование трудовых
              отношений и иных непосредственно связанных с ними отношений между субъектом и Компанией, исполнение субъектом и Компанией
              обязательств, предусмотренных локальными нормативными актами Компании, стандартами и политиками компаний группы, включая
              контроль за их соблюдением субъектом, в т. ч. при общении с клиентами Компании, осуществление комплаенс-контроля и
              противодействия коррупции, содействия субъектам в трудоустройстве, получении образования и продвижении по службе в т. ч.
              контроля количества и качества выполняемой работы и исполнения должностных обязанностей. Организация перемещений (поездок,
              перевозок, командировок и т. д.) субъекта, включая визовую и миграционную поддержку, содействие при перелетах и переездах, в
              том числе за рубеж или в пределах одной страны, организацию, участия субъекта в мероприятиях (конференциях, семинарах,
              опросах, мастер-классах, встречах, праздниках и др.). Обеспечение здравоохранения субъекта, включая оформление, заключение,
              исполнение, изменение, расторжение Компанией договора добровольного медицинского страхования, договора на организацию
              лабораторной диагностики и исследований (в т.ч. диагностики Covid-19), исполнения Компанией требований законодательства в
              сфере санитарно-эпидемиологического благополучия, в т. ч. постановлений главного государственного санитарного врача РФ и иных
              требований. Информационное обеспечение деятельности Компании, включая размещение информации на сайтах Компании, в
              корпоративных адресных и контактных справочниках, используемых Компанией, предоставление ПД субъекта контрагентам, в т. ч.
              потенциальным клиентам, обеспечение субъекта визитными карточками, оформление доверенностей на имя субъекта. Реализация
              Компанией финансовых обязательств перед субъектом, включая организацию оформления зарплатного проекта, выплату субъекту
              заработной платы, компенсаций и премий, осуществление пенсионных и налоговых отчислений, возврат НДФЛ и иные выплаты субъекту.
              Обеспечение безопасности субъекта и Компании, включая обеспечение личной безопасности субъекта, соблюдение пропускного режима
              и правил нахождения и поведения на территории Компании, использования корпоративных устройств, сохранности материальных и иных
              ценностей, находящихся в ведении Компании и субъекта, в т. ч. путем осуществления видеонаблюдения на территории Компании.
              Предоставление субъекту возможности использования корпоративных преимуществ — корпоративных программ, акций и привилегий, в
              т.ч. участия субъекта в организуемых третьими лицами программах обучения, вебинарах и образовательных мероприятиях, изучения
              иностранных языков и посещения спортивных организаций. Родственники работников — Осуществление кадрового учета в соответствии
              с требованиями законодательства РФ, а также, оформление выплат в случаях, предусмотренных ТК РФ и иными актами. Взаимодействие
              с контрагентами Компании, оказание услуг Контрагенты Компании (существующие и потенциальные) — Согласование, заключение,
              исполнение, изменение, расторжение договоров. Исполнение требований законодательства. Предоставление доступа в ИТ-системы
              Компании, контроль использования Субъекты, взаимодействующие с клиентами Компании (пользователи сайтов, виджетов, получатели
              рассылок, потенциальные и существующие клиенты, получатели рекламы и т. д.) — Предоставление клиентам Компании услуг по
              сквозной аналитике данных о пользователях, с которыми клиент взаимодействует, возможность использования виджетов и др.
              сервисов. Компания действует как обработчик по поручению клиента (оператора ПД) Управление делами Подписанты документов,
              учредители, директоры — Соблюдение требований корпоративного законодательства Доверитель, поверенный — Выдача, учет
              доверенностей Получатели / отправители корреспонденции — Учет и рассмотрение обращений, жалоб и иных требований
              (корреспонденции), получаемых Компанией. Иные бизнес-процессы Посетители офиса — Обеспечение безопасности на территории
              компании (пропускной режим, видеонаблюдение) Пользователи сайтов Компании — Предоставление доступа к сайтам, возможностям его
              использования. Анализ посещения сайта, оптимизация функционала Представители клиентов, потенциальных клиентов и контрагентов
              Компании (в т. ч. контрагенты — ИП и физические лица) — Взаимодействие с потенциальными клиентами и контрагентами. Продвижение
              услуг Компании. Направление сообщений регистрационного, информационного и рекламного характера. Проведение маркетинговых
              мероприятий и вебинаров рассылка уведомлений о мероприятии. <br />
              <br />
              3.3. Правовыми основаниями обработки персональных данных являются: • Конституция Российской Федерации; • Трудовой договор; •
              Гражданский кодекс Российской Федерации; • Федеральный закон от 27 июля 2006 г. №149-ФЗ «Об информации, информационных
              технологиях и о защите информации»; • Федеральный закон от 26 декабря 2008 г. №294-ФЗ «О защите прав юридических лиц и
              индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля»; • уставные
              документы Оператора; • договоры, заключаемые между Оператором и субъектами персональных данных; • договоры, заключаемые между
              Оператором и третьими лицами; • согласия субъектов персональных данных на обработку персональных данных; • иные основания.
              <br />
              <br />
              3.4. Общество обрабатывает персональные данные только при наличии хотя бы одного из указанных ниже условий в течение следующих
              сроков: (1) С согласия субъекта персональных данных на обработку его персональных данных В течение срока, на который было дано
              согласие на обработку персональных данных — В течение срока, на который было дано согласие на обработку персональных данных
              (2) Для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и
              выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей В течение срока,
              установленного соответствующими международными договорами или законами — В течение срока, установленного соответствующими
              международными договорами или законами (3) При необходимости обработки персональных данных, подлежащих опубликованию или
              обязательному раскрытию в соответствии с федеральным законом В течение срока, установленного соответствующими законами — В
              течение срока, установленного соответствующими законами (4) Для исполнения судебного акта, акта другого органа или
              должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном
              производстве В течение срока, необходимо для исполнения соответствующего акта — В течение срока, необходимо для исполнения
              соответствующего акта (5) В связи с участием лица в конституционном, гражданском, административном, уголовном
              судопроизводстве, судопроизводстве в арбитражных судах В течение срока участия в соответствующем судопроизводстве, включая
              сроки обжалования (оспаривания) судебных актов, кроме случаев, когда более длительный срок обработки персональных данных
              установлен действующим законодательством Российской Федерации — В течение срока участия в соответствующем судопроизводстве,
              включая сроки обжалования (оспаривания) судебных актов, кроме случаев, когда более длительный срок обработки персональных
              данных установлен действующим законодательством Российской Федерации (6) Для исполнения договора, стороной которого либо
              выгодоприобретателем или поручителем, по которому является субъект персональных данных, а также для заключения договора по
              инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться
              выгодоприобретателем или поручителем В течение срока действия такого договора, кроме случаев, когда более длительный срок
              обработки персональных данных установлен действующим законодательством Российской Федерации — В течение срока действия такого
              договора, кроме случаев, когда более длительный срок обработки персональных данных установлен действующим законодательством
              Российской Федерации (7) Для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если
              получение согласия субъекта персональных данных невозможно До момента, когда получение согласия субъекта персональных данных
              станет возможным или когда соответствующие основания, угрожающие жизни, здоровью или иным жизненно важным интересам, отпадут
              (в зависимости от того, какое обстоятельство наступит раньше) — До момента, когда получение согласия субъекта персональных
              данных станет возможным или когда соответствующие основания, угрожающие жизни, здоровью или иным жизненно важным интересам,
              отпадут (в зависимости от того, какое обстоятельство наступит раньше) (8) Для осуществления прав и законных интересов
              оператора или третьих лиц при условии, что при этом не нарушаются права и свободы субъекта персональных данных В течение
              срока, необходимого для осуществления прав и обеспечения законных интересов. Конкретный срок определяется Обществом с учётом
              положений настоящей Политики, внутренних документов и локальных нормативных актов Общества, а также принципов обработки
              персональных данных и требований действующего законодательства Российской Федерации, в том числе в части прекращения обработки
              персональных данных при достижении конкретных, заранее определенных и законных целей такой обработки — В течение срока,
              необходимого для осуществления прав и обеспечения законных интересов. Конкретный срок определяется Обществом с учётом
              положений настоящей Политики, внутренних документов и локальных нормативных актов Общества, а также принципов обработки
              персональных данных и требований действующего законодательства Российской Федерации, в том числе в части прекращения обработки
              персональных данных при достижении конкретных, заранее определенных и законных целей такой обработки <br />
              <br />
              3.5. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети «Интернет», обеспечивается
              запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан
              Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('4. Объем и категории обрабатываемых персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              4.1. Состав обрабатываемых персональных данных физических лиц, состоящих или ранее состоявших в трудовых, иных договорных и
              иных гражданско-правовых отношениях с Обществом, и (или) их законных представителей, в частности работников, уволенных
              работников, членов семьи и родственников работников и (или) уволенных работников, а также кандидатов на замещение вакантных
              должностей содержит следующие категории персональных данных: фамилия, имя, отчество, год рождения, месяц рождения, дата
              рождения, место рождения, адрес, семейное положение, социальное положение, имущественное положение, образование, профессия,
              доходы, паспортные данные, сведения о гражданстве, данные пенсионного, медицинского страхования, ИНН, СНИЛС, сведения о
              воинском учете, сведения о наградах, поощрениях, почетных званиях, контактные данные, степень родства, фотография, иные данные
              в соответствии с требованиями законодательства Российской Федерации. <br />
              <br />
              4.2. Состав обрабатываемых персональных данных клиентов/контрагентов и их представителей; пользователей Интернет-сервисов и
              приложений; заявителей с обращениями содержит следующие категории персональных данных, фамилия, имя, отчество, контактные
              данные (телефон, e-mail), должность, место работы, паспортные данные, содержание сообщений (в т.ч. медиафайлы), файлы
              «Cookies» посетителей сайта и техническая информация о пользователях (дата регистрации, параметры устройства и веб-приложений,
              IP-адрес, история сообщений, геолокация, параметры сессии). Более подробно о собираемой технической информации указано в
              Политике использования файлов cookie. <br />
              <br />
              4.3. Общество осуществляет обработку специальных категорий персональных данных только в случаях, когда такая обработка
              допускается Законом о персональных данных: субъект персональных данных дал согласие в письменной форме на обработку своих
              персональных данных, обработка персональных данных осуществляется в соответствии с законодательством Российской Федерации,
              обработка персональных данных необходима для установления или осуществления прав субъекта персональных данных или третьих лиц.
              Обработка специальных категорий персональных данных незамедлительно прекращается, когда достигнуты цели, для достижения
              которых осуществлялась их обработка, если иное не установлено законодательством Российской Федерации. <br />
              <br />
              4.4. Полный перечень персональных данных, обрабатываемых в Обществе, определяется в соответствии с локальными нормативными
              актами Общества с учетом целей обработки персональных данных.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('5. Порядок и условия обработки персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              5.1. Общество осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
              использование, передачу (распространение, предоставление, доступ), извлечение, блокирование, обезличивание, удаление,
              уничтожение персональных данных. <br />
              <br />
              5.2. Обработка персональных данных осуществляется с применением: • автоматизированной обработки персональных данных с
              передачей по сети Интернет или без таковой; • неавтоматизированной обработки персональных данных; • смешанных способов
              обработки. <br />
              <br />
              5.3. Обработка персональных данных субъекта персональных данных осуществляется с его согласия на обработку персональных
              данных, а также без такового, если Обработка персональных данных необходима для исполнения договора, стороной которого либо
              выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по
              инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться
              выгодоприобретателем или поручителем или в иных случаях, предусмотренных Законодательством о персональных данных. <br />
              <br />
              5.4. Передача персональных данных третьему лицу осуществляется только с согласия субъекта персональных данных или в случаях,
              прямо предусмотренных законодательством Российской Федерации. <br />
              <br />
              5.5. Общество раскрывает информацию третьим лицам только в случаях, предусмотренных настоящей Политикой или применимым
              законодательством, и только в объёме, необходимом исходя из цели раскрытия.
              <br />
              <br />
              5.6. Общество вправе поручить обработку персональных данных другому лицу и (или) осуществлять обработку персональных данных по
              поручению другого лица в порядке и на условиях, предусмотренных требованиями частей 3−6 статьи 6 Федерального закона от 27
              июля 2006 г. №152-ФЗ «О персональных данных». <br />
              <br />
              5.7. Лицам, получающим доступ к персональным данным по поручению, а равно третьим лицам, которым Общество предоставляет доступ
              по иным законным основаниям, передаётся строго ограниченный набор данных, необходимый для исполнения их функций. <br />
              <br />
              5.8. Общество принимает все зависящие меры, чтобы обеспечить конфиденциальность передачи данных. Обработчики и третьи лица
              обязуются соблюдать принципы и правила обработки персональных данных, предусмотренные Федеральным законом №152-ФЗ «О
              персональных данных» (включая ст. 18.1 и ч. 5 ст. 18), иными законами и подзаконными актами. Для каждого обработчика в
              договоре определены: перечень обрабатываемых персональных данных; цели их обработки; перечень действий (операций), которые
              будут совершаться с персональными данными обработчиком; обязанности обработчика по соблюдению конфиденциальности и обеспечению
              безопасности персональных данных при их обработке, а также перечень принимаемых обработчиком мер по обеспечению защиты
              обрабатываемых им персональных данных, включая требование об уведомлении Общества об инцидентах с персональными данными;
              обязанность по запросу Общества в течение срока действия поручения на обработку персональных данных предоставлять Обществу
              документы и иную информацию, подтверждающие принятие мер и соблюдение в целях исполнения поручения Общества требований,
              установленных Федеральным законом от 27.07.2006№152 ФЗ"О персональных данных". <br />
              <br />
              5.10. Перечень лиц, обрабатывающих персональные данные субъекта по поручению Общества или получающих данные субъекта от
              Общества подлежит раскрытию в соответствующем согласии на обработку персональных данных.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('6. Хранение персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              6.1. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем
              этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законодательством
              Российской Федерации либо договором, стороной которого является субъект персональных данных. <br />
              <br />
              6.2. Хранение персональных данных осуществляется с учетом обеспечения режима их конфиденциальности. <br />
              <br />
              6.3. Оператор обеспечивает сбор персональных данных и их обработку с использованием баз данных, находящихся на территории
              Российской Федерации. <br />
              <br />
              6.4. Персональные данные передаются на архивное хранение в соответствии с законодательством Российской Федерации об архивном
              деле, уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих
              целей, если иное не предусмотрено законодательством Российской Федерации либо договором, стороной которого является субъект
              персональных данных.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('7. Трансграничная передача персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              7.1. Общество осуществляет трансграничную передачу персональных данных в соответствии с требованиями действующего
              законодательства Российской Федерации. Трансграничная передача персональных данных на территории иностранных государств,
              являющихся сторонами Конвенции Совета Европы о защите физических лиц при автоматизированной обработке персональных данных, а
              также иных иностранных государств, обеспечивающих адекватную защиту прав субъектов персональных данных, осуществляется в
              соответствии с требованиями действующего законодательства Российской Федерации. <br />
              <br />
              7.2. Трансграничная передача персональных данных на территории иностранных государств, не обеспечивающих адекватной защиты
              прав субъектов персональных данных, осуществляется в соответствии с требованиями действующего законодательства Российской
              Федерации.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('8. Меры, принимаемые для обеспечения выполнения обязанностей оператора при обработке персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              8.1. Общество принимает необходимые правовые, организационные и технически меры, позволяющие обеспечить безопасность
              персональных данных, а также соблюдение прав субъектов персональных данных, требований законодательства Российской Федерации и
              локальных актов Общества в области обработки и защиты персональных данных. <br />
              <br />
              8.2. Для обеспечения выполнения обязанностей оператора, предусмотренных законодательством Российской Федерации в области
              персональных данных, Общество принимает следующие меры: • физическую защиту помещений, где производится обработка персональных
              данных; назначение лица, ответственного за организацию обработки персональных данных в Обществе; • принятие локальных
              нормативных актов и иных документов по вопросам обработки и защиты персональных данных; • ознакомление работников Общества,
              непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о
              персональных данных, в том числе требованиями к защите персональных данных, локальными нормативными актами и иными документами
              Общества по вопросам обработки персональных данных; • разработка и утверждение плана внутренних проверок режима защиты
              персональных данных; • получение согласий субъектов персональных данных на обработку их персональных данных, за исключением
              случаев, предусмотренных Российской Федерации; • обособление персональных данных, обрабатываемых без использования средств
              автоматизации, от иной информации; • обеспечение раздельного хранения персональных данных и их материальных носителей,
              обработка которых осуществляется в разных целях и которые содержат разные категории персональных данных; • хранение
              материальных носителей персональных данных с соблюдением условий, обеспечивающих сохранность персональных данных и исключающих
              несанкционированный доступ к ним; • осуществление внутреннего контроля соответствия обработки персональных данных Федеральному
              закону и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, настоящей
              Политике, локальным нормативным актам Общества; • распределение обязанностей по обеспечению и реализации мер защиты между
              структурными подразделениями. <br />
              <br />
              8.3. Обеспечение безопасности персональных данных достигается, в частности, путем создания системы защиты персональных данных,
              основанной на следующих принципах: • определением угроз безопасности персональных данных при их обработке в информационных
              системах персональных данных субъектов; • применением организационных и технических мер по обеспечению безопасности
              персональных данных субъектов при их обработке в информационных системах персональных данных, необходимых для выполнения
              требований к защите персональных данных субъектов, исполнение которых обеспечивает установленные Правительством Р Ф уровни
              защищенности персональных данных; • применением прошедших в установленном порядке процедуру оценки соответствия средств защиты
              информации; • оценкой эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию
              информационной системы персональных данных; • учетом машинных носителей персональных данных; • обнаружением фактов
              несанкционированного доступа к персональным данным и принятием мер, в том числе мер по обнаружению, предупреждению и
              ликвидации последствий компьютерных атак на информационные системы персональных данных и по реагированию на компьютерные
              инциденты в них; • восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного
              доступа к ним; • установлением правил доступа к персональным данным, обрабатываемым в информационной системе персональных
              данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной системе
              персональных данных; • контролем за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности
              информационных систем персональных данных: • применением иных мер.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('9. Порядок взаимодействия с субъектами персональных данных')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              9.1. субъект, персональные данные которого обрабатываются в Обществе, вправе отозвать ранее данное согласие на обработку
              персональных данных, а равно получить доступ к своим персональным данным, в том числе к следующей информации: • подтверждение
              факта обработки персональных данных; • правовые основания и цели обработки персональных данных; • цели и применяемые способы
              обработки персональных данных; • наименование и место нахождения оператора, сведения о лицах (за исключением работников
              оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании
              договора с оператором или на основании законодательства Российской Федерации; • перечень обрабатываемых персональных данных,
              относящиеся к соответствующему субъекту, и источник их получения; • сроки обработки персональных данных и сроки их хранения; •
              информацию об осуществлённой или о предполагаемой трансграничной передаче данных; • наименование лица, осуществляющего
              обработку персональных данных по поручению оператора, в случае если обработка поручена третьему лицу, либо сведения о
              трансграничной передачи. <br />
              <br />
              9.2. Если иной порядок взаимодействия Общества и субъекта персональных данных не предусмотрен соответствующим документом между
              ними (например, договором или текстом согласия на обработку персональных данных), для реализации указанных прав субъекту
              персональных данных необходимо направить в адрес Общества соответствующее требование: • в письменной форме и подписанное
              собственноручной подписью — по адресу: 610050, г. Киров, ул. Московская, д. 199 • в форме электронного документа — на
              электронную почту: kirov004@yandex.ru <br />
              <br />
              9.3. Заявление лица должно содержать описание требований, а также информацию, способную его идентифицировать как субъекта
              персональных данных, чьи данные обрабатывает Общество. <br />
              <br />
              9.4. Во избежание избыточной обработки персональных данных при реагировании на запросы субъекта Общество руководствуется
              принципом соразмерности предоставленных субъектом для идентификации обрабатываемых сведений, что подразумевает: • в случае,
              если субъектом персональных данных дано согласие в соответствии с ч. 4 ст. 9 Федеральным законом от 27.07.2006 №152 ФЗ «О
              персональных данных», а именно путем проставления собственноручной подписи на бумажном носителе, содержащем согласие на
              обработку персональных данных, а равно в форме электронного документа, подписанного аналогом собственноручной подписи, то
              запрос должен содержать следующие реквизиты (a) ФИО субъекта персональных данных; (b) номер основного документа,
              удостоверяющего личность субъекта персональных данных или его представителя, (с) сведения о дате выдачи указанного документа и
              выдавшем его органе, ЛИБО иные данные, позволяющие однозначно идентифицировать субъекта персональных данных; (d) сведения,
              подтверждающие участие субъекта персональных данных в отношениях с Обществом, ЛИБО сведения, иным способом подтверждающие факт
              обработки персональных данных Обществом: (e) рукописная подпись субъекта персональных данных или его представителя на бумажном
              носителей, а равно электронная подпись; • во всех иных случаях субъект персональных данных для своей идентификации указывает
              те сведения, которые им были оставлены в соответствующем согласии на обработку персональных данных (например, электронная
              почта и номер телефона). <br />
              <br />
              9.5. Общество рассматривает обращения, дает разъяснения и предпринимает меры по защите данных в десятидневный срок. В случае
              претензий и жалоб со стороны субъекта персональных данных, Общество принимает все необходимые меры для устранения возможных
              нарушений, установления виновных лиц и урегулирования спорных ситуаций в досудебном порядке. <br />
              <br />
              9.6. субъект персональных данных вправе обжаловать действия (бездействия) и решения Общества, нарушающие его права при
              обработке персональных данных, в уполномоченный орган по защите прав субъектов персональных данных (Роскомнадзор) и в суд в
              порядке, установленном законодательством Российской Федерации. <br />
              <br />
              9.7. В случае отзыва субъектом персональных данных согласия на обработку персональных данных Общество вправе продолжить
              обработку персональных данных без согласия субъекта персональных данных при наличии оснований, предусмотренных
              законодательством Российской Федерации.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('10. Заключительные положения')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              10.1. Иные права и обязанности Общества как Оператора персональных данных определяются законодательством Российской Федерации
              в области персональных данных. Общество, его должностные лица и Работники несут гражданско-правовую, административную и иную
              ответственность за несоблюдение принципов и условий обработки персональных данных, а также за разглашение или незаконное
              использование персональных данных в соответствии с законодательством Российской Федерации <br />
              <br />
              10.2. Реквизиты и контактная информация Оператора персональных данных:
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={600}
              color={'black'}
            >
              ООО «Группа компаний «Белый Медведь»
            </NewText>
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              610050, г. Киров, улица Московская, дом 199, этаж 2 <br />
              ИНН 4345410051 <br />
              КПП 434501001 <br />
              Тел: 8 812 507-63-33
            </NewText>
          </List>
        ) : (
          <List>
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('ЛИЦЕНЗИОННЫЙ ДОГОВОР (ОФЕРТА)')}
            </NewText>
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              {t('Редакция 2023.12.10 от «10» Декабря 2023')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('1. Термины и определения')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              Применяемые в настоящем Договоре термины означают следующее:
              <br />
              Программный продукт – программа для ЭВМ «Telebon» Личный кабинет – инструмент доступа к Программному продукту, расположенный
              на официальном сайте Программного продукта: https://telebon.ru. Конечный пользователь – любое лицо, которому Лицензиатом
              предоставляется доступ к Личному кабинету Лицензиата. Активированная учетная запись – это пользователь, которого пригласили в
              аккаунт Лицензиата и не деактивировали в разделе управления сотрудниками. Установка – установка Программного продукта (в том
              числе запись и хранение в памяти ЭВМ (сервера, принадлежащего Лицензиату), а также совершение иных необходимых (обычно
              требуемых) установочных действий с целью записи файлов для надлежащего функционирования Программного продукта на техническом
              средстве (ЭВМ) Лицензиата и дальнейшего использования Программного продукта на данном ЭВМ. Saas-версия – версия Программного
              продукта, которая предоставляется без скачивания дистрибутива на серверах Лицензиара. Договорная территория — территория
              Российской Федерации, республики Беларусь, Казахстана, Киргизии и Армении. Лицензионное вознаграждение – денежная сумма,
              уплачиваемая Лицензиатом за право использования Программного продукта. Раздел «Тарифы» – раздел сайта Программного продукта,
              который содержит информацию о размерах лицензионного вознаграждения. Тариф – размер лицензионного вознаграждения в
              соответствии с предоставленной функциональностью Программного продукта. Учетный период использования (учетный период) –
              подлежащий оплате период использования Лицензиатом Программного продукта. Модуль Программного продукта – набор отдельных
              функций Программного продукта, не входящий в базовую комплектацию программы и предоставляемых Лицензиату на отдельных условиях
              и за отдельную плату. Некоммерческое использование – использование Программного продукта на условиях простой
              (неисключительной) лицензии на основе бесплатного Тарифа. Коммерческое использование – использование Программного продукта на
              условиях простой (неисключительной) лицензии на основе платного Тарифа Бесплатный период – период времени, указанный в разделе
              «Тарифы», в который Лицензиат знакомится с Программным продуктом без взимания с него платы. При этом Лицензиату
              предоставляется для ознакомления функциональность Программного продукта в соответствии с выбранным Тарифом. Регистрация
              банковской карты – операция в Личном кабинете, в рамках которой Лицензиат указывает данные своей кредитной/дебетовой карты.
              Платежная система – система, с помощью которой осуществляются все операции по банковским картам. Электронная подпись —
              усиленная квалифицированная электронная подпись, соответствующая требованиям Федерального закона № 63-ФЗ «Об электронной
              подписи» от 06.04.2011 и действующему законодательству РФ в сфере электронной подписи. Электронный документооборот (ЭДО) —
              процесс обмена между Сторонами в системе юридически значимого электронного документооборота документами, составленными в
              электронном виде и подписанными электронной подписью, используемой Сторонами. Оператор ЭДО — организация, обеспечивающая обмен
              открытой и конфиденциальной информацией по телекоммуникационным каналам связи в рамках электронного документооборота между
              Сторонами в системе ЭДО.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('2. Предмет договора')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              2.1. Лицензиар предоставляет Лицензиату право на Коммерческое или Некоммерческое использование Программного продукта на
              условиях простой (неисключительной) лицензии в целях использования программного продукта на Договорной территории в пределах
              прав использования, определенных настоящим Договором, а также предоставляет за дополнительное вознаграждение отдельные модули
              Программного продукта, оказывает дополнительные услуги в соответствии с настоящим Договором.
              <br />
              <br />
              2.2. Право на использование Программного продукта передается на основании оферты Лицензиара.
              <br />
              <br />
              2.3. Использование Программного продукта осуществляется путем открытия доступа через сеть Интернет к Программному продукту,
              расположенному на сайте: https://telebon.ru. Способы использования: установка Программного продукта или использование
              SaaS-версии Программного продукта в соответствии с функциональным предназначением.
              <br />
              <br />
              2.4. Право использования Программного продукта передается без права дальнейшего сублицензирования.
              <br />
              <br />
              2.5. За отдельную плату, подлежащую согласованию Сторонами отдельным соглашением, Лицензиар оказывает следующие услуги:
              <br />
              • удаленная установка программного продукта на сервер Лицензиата;
              <br />• удаленное обновление программного продукта на сервере Лицензиата;
              <br />• обучение работников Лицензиата правилам и порядку работы с программным продуктом..
              <br />
              За отдельную плату Лицензиату также могут быть предоставлены дополнительные модули Программного продукта. Размер платы
              указывается в разделе «Тарифы».
              <br />
              <br />
              2.6. Перед использованием Программного продукта Лицензиат обязан ознакомиться с условиями настоящего Договора и принять их.
              Настоящий Договор является офертой, которая может быть акцептована Лицензиатом путем совершения одного или нескольких
              действий, свидетельствующих о согласии Лицензиата с условиями Договора:
              <br />
              <br />
              2.6.1. Путем присоединения Лицензиата к настоящему Договору в форме активных действий, а именно проставление галочки в окне «Я
              согласен со всеми условиями Лицензионного договора и безоговорочно их принимаю», одновременно с внесением своих учетных данных
              в Личном кабинете;
              <br />
              <br />
              2.6.2. Путем оплаты лицензионного вознаграждения и начала фактического использования Лицензиатом Программного продукта при
              Коммерческом использовании.
              <br />
              <br /> 2.6.3. Путем начала фактического использования Лицензиатом Программного продукта в Бесплатном периоде.
              <br />
              <br />
              2.6.4. Путем начала фактического использования Лицензиатом Программного продукта при Некоммерческом использовании.
              <br />
              <br />
              2.6.5. При постоплатной системе с момента активации и выбора Лицензиатом постоплатной системы расчетов.
              <br />
              <br />
              2.7. Каждым использованием Программного продукта, Лицензиат выражает свое согласие с условиями лицензионного договора в
              редакции, которая действовала на момент такого фактического использования. Акцептуя настоящий Договор, т.е. заключая его с
              Лицензиаром, Лицензиат соглашается со всеми его условиями и положениями без оговорок.
              <br />
              <br />
              2.8. Настоящий Договор не требует оформления в печатном виде и последующего подписания Сторонами, обладает юридической силой в
              силу акцепта Лицензиата способами, указанными в п. 2.6 Договора, и считается заключенным в простой письменной форме.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3. Права и обязанности сторон')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3.1.Лицензиат обязан:')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              3.1.1. Использовать Программный продукт только в пределах и способами, предусмотренными настоящим Договором;
              <br />
              <br />
              3.1.2. Уплатить лицензионное вознаграждение за Программный продукт в размере и в порядке, предусмотренными настоящим
              Договором.
              <br />
              <br /> 3.1.3. Воздерживаться от:
              <br />• любых модификаций и декомпилирования Программного продукта;
              <br />• воспроизведения Программного продукта, в том числе путем записи в память ЭВМ;
              <br />• распространения (копирования) Программного продукта;
              <br />• осуществления иных действий, направленных на извлечение коммерческой выгоды в отношениях с третьими лицами, возникшей
              из использования Программного продукта;
              <br />• предоставления сублицензий на использование Программного продукта;
              <br />• обнародования Программного продукта;
              <br />• внесения каких-либо изменений в объектный код Программного продукта;
              <br />• совершения любых иных действий в отношении Программного продукта, нарушающих российские и/или международные нормы
              законодательства.
              <br />
              <br />
              3.1.4. Самостоятельно отслеживать актуальные редакции тарифов, действующих в отношении использования Программного продукта и
              отображающихся в разделе «Тарифы» на сайте Программного продукта и/или в Личном кабинете Лицензиата.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3.2. Лицензиат вправе:')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              3.2.1. Осуществлять любые действия, связанные с функционированием Программного продукта и его использованием в соответствии с
              его назначением.
              <br />
              <br />
              3.2.2. Предоставлять своим конечным пользователям удаленный доступ к программному продукту (к Личному кабинету), а также к
              информации, сформированной в результате использования Программного продукта. При этом количество предоставляемых Лицензиатов
              прав доступа к Программному продукту не должно превышать количество лицензий, приобретенных Лицензиатом у Лицензиара (кроме
              случая Некоммерческого использования). <br />
              <br />
              3.2.3 Приобретать дополнительную функциональную составляющую Программного продукта, посредством оплаты эксплуатации Модуля
              Программного продукта Лицензиата (Партнера) при помощи интерфейса Личного кабинета.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3.3. Лицензиар обязан:')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              3.3.1. Предоставить Лицензиату права использования на Программный продукт, определенные настоящим Договором.
              <br />
              <br />
              3.3.2. В течение 3 (трех) рабочих дней с момента исполнения Лицензиатом обязанности по оплате лицензионного вознаграждения,
              предоставить Лицензиату доступ к Программному продукту в рамках его Коммерческого использования. Доступ к Программному
              продукту должен обеспечиваться ежедневно и круглосуточно, за исключением времени проведения профилактических мероприятия,
              составляющих не более 10 часов в месяц.
              <br />
              <br />
              3.3.3. Обеспечивать сопровождение Программного продукта по вопросам его надлежащей работы.
              <br />
              <br />
              3.3.4. Предоставлять информацию об обновлениях Программного продукта.
              <br />
              <br />
              3.3.5. Безвозмездно устранять сбои в работе Программного продукта, возникшие не по вине Лицензиара и не связанные с нарушением
              правил использования Программного продукта. 3.3.6 Обеспечивать конфиденциальность данных, размещенных Лицензиатом в
              Программном продукте, на весь период их нахождения на сервере Лицензиара
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('3.4. Лицензиар вправе:')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              3.4.1. В случае просрочки Лицензиатом внесения очередной части лицензионного вознаграждения, перевести использование
              Программного продукта для Лицензиата в режим «только для чтения» и/или отказаться от исполнения настоящего Договора в
              одностороннем внесудебном порядке, письменно предупредив об этом Лицензиата путем направления соответствующего уведомления в
              Личный кабинет или по электронной почте с последующей деактивацией или удалением аккаунта Лицензиата.
              <br />
              <br />
              3.4.2. Привлекать для исполнения настоящего Договора третьих лиц, оставаясь ответственным за их действия.
              <br />
              <br />
              3.4.3. В одностороннем внесудебном порядке вносить изменения в настоящий Договор, уведомляя об этом Лицензиата путем
              размещения соответствующей информации на сайте: https://telebon.ru
              <br />
              <br />
              3.4.4. В одностороннем порядке изменять количество конечных пользователей, допустимых для Лицензиата при Некоммерческом
              использовании. Актуальное количество конечных пользователей для Некоммерческого использования отображается в разделе «Тарифы»
              на сайте Программного продукта и/или в Личном кабинете Лицензиата (с учетом приоритета, установленного п. 5.1. настоящего
              Договора). Лицензиар вправе направить Лицензиату соответствующее уведомление об изменении тарифа в указанной части по
              электронной почте или через Личный кабинет. <br />
              <br />
              3.4.5 Вносить изменения в Программный продукт без уведомления Лицензиата в любое время и по любой причине, в том числе, но не
              ограничиваясь, в целях удовлетворения потребностей других лицензиатов, требований конкурентоспособности, или в целях
              исполнения требований нормативных актов. Лицензиар оставляет за собой право добавлять новые свойства и функциональные
              возможности Программы или удалять из Программы уже существующие свойства и функциональные возможности. <br />
              <br />
              3.4.6 Агрегировать, систематизировать и анализировать информацию Лицензиата, в том числе конфиденциальную, с целью создания
              информационно-аналитических отчетов и баз данных, при этом Лицензиар гарантирует нераспространение и сохранность
              конфиденциальной информации, содержащейся в отчетах и базах в соответствии с настоящим Договором и действующим
              законодательством Российской Федерации.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('4. Предоставление права использования Программного продукта')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              4.1. Порядок предоставления права использования Программного продукта указан в «Пользовательском соглашении», размещенными на
              сайте Программного продукта.
              <br />
              <br />
              4.2. При Коммерческом использовании Лицензиар предоставляет Лицензиату Бесплатный период. Длительность Бесплатного периода
              определяется и сообщается Лицензиату в момент выбора Тарифа. По окончании Бесплатного периода, Лицензиат может перейти на
              Коммерческое использование Программного продукта, оплатив вознаграждение за соответствующий Тариф.
              <br />
              <br />
              4.3. Актуальная стоимость Коммерческого использования Программного продукта указана в разделе «Тарифы» на сайте Программного
              продукта и/или в Личном кабинете Лицензиата. При окончании Бесплатного периода Лицензиату направляется уведомление в Личном
              кабинете - о необходимости подтвердить переход на Коммерческое использование программного продукта и предоставлении детальных
              учетных данных для осуществления оплаты суммы лицензионного вознаграждения. Детальные учетные данные предоставляются
              Лицензиатом посредством заполнения соответствующей формы в Личном кабинете.
              <br />
              <br />
              4.4. При заполнении детальных данных Лицензиат также указывает: в случае приобретения Saas-версии программного продукта:
              продукт, количество лицензий (количество конечных пользователей), учетный период приобретения простой (неисключительной)
              лицензии программного продукта, вид дополнительной услуги, оказание которой заказывает Лицензиат.
              <br />
              <br />
              4.5. При переходе на Коммерческое использование, Лицензиат обязан начать уплачивать лицензионное вознаграждение на выбранных
              условиях Тарифа.
              <br />
              <br />
              4.6. Отказом Лицензиата от использования программного продукта является отсутствие оплаты Лицензионного вознаграждения.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('5. Порядок расчетов')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              5.1. Лицензионное вознаграждение за предоставленные права использования программного продукта выплачиваются Лицензиатом за
              учетный период использования, либо за фактическое количество дней работы активированных учетных записей. Размер лицензионного
              вознаграждения, а также детали расчета учетного периода и/или времени работы активированных учетных записей указывается на
              сайте Программного продукта в разделе «Тарифы», и/или в Личном кабинете при активации Лицензиатом выбранного тарифа. В случае
              отсутствия размера лицензионного вознаграждения в разделе «Тарифы» на сайте программного продукта, а также в случае
              расхождений между размером лицензионного вознаграждения, указанного в разделе «Тарифы» и в Личном кабинете Лицензиата, Стороны
              руководствуются тарифами, указанными в Личном кабинете Лицензиата. В любом случае приоритет имеют тарифы, указанные в Личном
              кабинете Лицензиата. Окончательная сумма лицензионного вознаграждения указывается Лицензиаром при выборе Лицензиатом тарифа в
              Личном кабинете, а также в счете, выставляемом Лицензиату – юридическому лицу. Параметры изменения выбранного тарифа, а также
              параметры добавления/удаления конечных пользователей также указываются в разделе «Тарифы» и/или в Личном кабинете Лицензиата.
              Лицензиат несет полную ответственность за самостоятельное ознакомление с вносимыми изменениями в тарифы и обладанием
              актуальной информацией.
              <br />
              5.2. Лицензиату - юридическому лицу выставляется счёт. Счет выставляется в электронном виде (в Личном кабинете). Счет может
              быть выставлен за учетный период использования. Начало учетного периода исчисляется с момента выбора тарифа в Личном кабинете.
              Счет выставляется каждый учетный период на основании детальных учетных данных, предоставленных Лицензиатом. Стоимость (размер
              лицензионного вознаграждения) формируется исходя из данных, указанных Лицензиатом, и выбранного тарифа.
              <br />
              5.3. Лицензиат обязан оплатить сумму лицензионного вознаграждения в течение срока оплаты счёта, выставленного Лицензиаром (или
              требования об оплате в иной форме). Срок оплаты счёта указан в Личном кабинете Лицензиата в разделе “Оплата”. Исключением
              является случай, предусмотренный п. 5.4 настоящего Договора. В случае если денежные средства не поступят на счет Лицензиара в
              срок оплаты счёта, последний вправе перевести его в режим «только для чтения» или приостановить доступ Лицензиата к
              программному продукту. Режим «только для чтения» не позволяет совершать активные действия при использовании программного
              продукта (кроме чтения имеющихся материалов), осуществлять изменения в программном продукте, также удалять/добавлять конечных
              пользователей. В случае допущения просрочек в оплате лицензионного вознаграждения, Лицензиар также вправе применить положения
              п. 3.4.1. Оплата счёта по истечению срока оплаты, может быть признана Лицензиаром недействительной. В случае, если оплата
              Лицензионного вознаграждение не будет произведена Лицензиатом, Лицензиар оставляет за собой право удалить аккаунт Лицензиата.
              При оплате Лицензионного вознаграждения после удаления аккаунта восстановление аккаунта производится по согласованию сторон.
              <br />
              5.4. В случае, когда Лицензиат является физическим лицом и использует для оплаты зарегистрированную на сайте Программного
              продукта кредитную/дебетовую карту, он вправе выбрать постоплатную систему оплаты лицензионного вознаграждения путем
              соответствующего выбора в Личном кабинете. В случае выбора названной системы Лицензиар ежемесячно направляет Лицензиату
              калькуляцию с указанием количества активных конечных пользователей и количества дней по каждому пользователю - в течение
              учетного периода (месяца) и с отражением суммы лицензионного вознаграждения, подлежащего оплате. Указанная сумма автоматически
              списывается с банковской карты Лицензиата, на что он дает свое согласие фактом выбора постоплатной системы расчетов. Настоящим
              Лицензиат также дает свое согласие на блокировку денежных средств на банковской карте, выбранной для расчетов с Лицензиаром,
              при активации постоплатного тарифа. Точная сумма денежных средств, подлежащих блокировке, указывается в Личном кабинете при
              активации Коммерческого использования. Заблокированные денежные средства в дальнейшем подлежат возврату Лицензиату. В случае,
              если не удается произвести списание денежных средств по причине возникновения любой ошибки, в том числе списание не дает
              провести платежная система, то Лицензиат обязан обновить данные банковской карты в Личном кабинете или запросить у Лицензиара
              ссылку на оплату задолженности. Обязанность по оплате задолженности возникает у физического лица, который осуществлял
              Регистрацию банковской карты. Лицензиат, который зарегистрировал чужую банковскую карту (не эмитированную на него лично) в
              своём Личном кабинете, несёт ответственность по оплате всех счетов в аккаунте.
              <br />
              5.5. Все расчеты производятся в безналичной денежной форме. Моментом исполнения Лицензиатом своих обязанностей по оплате
              считается момент поступления денежных средств на расчетный счет Лицензиара.
              <br />
              5.6. Расчеты производятся в российских рублях.
              <br />
              5.7. Размер лицензионного вознаграждения может быть изменен Лицензиаром в одностороннем порядке на новые учетные периоды с
              предварительным уведомлением Лицензиата не менее чем за 10 (десять) календарных дней до начала их действия путем уведомления в
              Личном кабинете и/или по электронной почте и/или путем внесения изменений в раздел «Тарифы» на сайте Программного продукта.
              <br />
              5.8. Лицензионное вознаграждение не облагается НДС в связи с применением Лицензиаром упрощенной системы налогообложения.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('6. Ответственность')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              6.1. В случае ненадлежащего исполнения обязательств по настоящему Договору Стороны несут ответственность в соответствии с
              действующим законодательством РФ.
              <br />
              6.2. Ни одна из сторон не несет ответственности перед другой стороной за задержку или невыполнение обязательств, обусловленные
              обстоятельствами, возникшими помимо воли и желания сторон и которые нельзя ни предвидеть или ни предотвратить, включая
              объявленную или фактическую войну, гражданские волнения, эпидемии, блокаду, эмбарго, землетрясения, наводнения, пожары и
              другие стихийные бедствия.
              <br />
              6.3. Если обстоятельства непреодолимой силы действуют на протяжении 3 (трех) последовательных месяцев, то настоящий Договор
              может быть расторгнут любой из сторон путем направления уведомления другой стороне.
              <br />
              6.4. Лицензиат приобретает право на использование Программного продукта в объеме, оговоренном настоящим Договором, и несет
              ответственность за его использование в соответствии с рекомендациями, изложенными в эксплуатационной документации, и
              действующим законодательством Российской Федерации.
              <br />
              6.6. Лицензиар не несет ответственность за прямые или косвенные убытки, включая упущенную выгоду, иной ущерб или вред,
              перерывы в хозяйственной деятельности, потерю деловой информации и т.п., которые могут возникнуть у Лицензиата в результате
              использования Программного продукта.
              <br />
              6.7. Лицензиат использует предоставленные права использования программного продукта на собственный риск. Лицензиар не
              принимает на себя ответственность за соответствие функциональности Программного продукта цели его использования, за любой
              ущерб, понесенный Лицензиатом ввиду утери им своих данных, необходимых для доступа к Программному продукту, а также за
              качество предоставляемых третьими лицами сервисов, необходимых для работы с Программным продуктом. Лицензиат самостоятельно
              несет ответственность за сохранность и безопасность своего пароля.
              <br />
              6.8. В случае утери данных Лицензиата по его вине, Лицензиар при наличии технической возможности восстанавливает данные
              Лицензиата на возмездной основе согласно заключаемому дополнительному соглашению.
              <br />
              6.9 При отправке голосовых сообщений посредством использования программного продукта Лицензиат гарантирует, что осуществляет
              отправку исключительно лицам, давшим согласие на получение информации данным способом от Лицензиата, в порядке, установленном
              действующим законодательством Российской Федерации. При распространении информации рекламного характера посредством отправки
              сообщений через Программу Лицензиат выступает в качестве рекламораспространителя в соответствие с требованиями Федерального
              закона от 13.03.2006 N 38-ФЗ «О рекламе».
              <br />
              6.10 Лицензиар не имеет технической возможности контролировать содержимое передаваемой Лицензиатом (Пользователями)
              информации, номера получателей сообщений, на которые осуществляется отправка информации Лицензиата, наличие согласия
              получателей сообщений Лицензиата на получение информации от Лицензиата, а также достоверность иной идентифицирующей Лицензиата
              информации, содержащейся в отправляемых сообщениях.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('7. Срок действия Договора')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              7.1. Договор вступает в силу с момента его заключения в порядке, предусмотренном в п. 2.7 (акцепта оферты).
              <br />
              7.2. При Коммерческом использовании настоящий Договор будет действовать на протяжении всех учетных периодов, оплаченных
              Лицензиатом в рамках настоящего Договора, и автоматически пролонгируется фактом оплаты лицензионного вознаграждения за
              следующий учетный период.
              <br />
              7.3. Лицензиар вправе отказаться от исполнения настоящего Договора в одностороннем внесудебном порядке в случае непоступления
              оплаты (лицензионного вознаграждения) за программный продукт в сроки, установленные настоящим Договором. При Некоммерческом
              использовании Лицензиар вправе отказаться от исполнения настоящего Договора в одностороннем внесудебном порядке в любое время
              и без объяснения причин, уведомив Лицензиата в Личном кабинете.
              <br />
              7.4. Лицензиат вправе отказаться от исполнения настоящего Договора путем отказа от использования программного продукта и
              письменного уведомления об этом Лицензиара. Неуплата лицензионного вознаграждения также является отказом Лицензиата от
              использования программного продукта. В случае отказа Лицензиата от Договора (вне зависимости от причин отказа), внесенная
              сумма лицензионного вознаграждения за весь учетный период, внесенная Лицензиатом (в том числе в качестве предоплаты) не
              подлежит возврату Лицензиату.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('8. Разрешение споров')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              8.1. Настоящий Договор, а также все правоотношения Сторон, связанные с заключением, изменением, исполнением, расторжение
              настоящего Договора и связанные с ним, регулируется материальным и процессуальным правом РФ.
              <br />
              8.2. Все споры, возникающие между Лицензиатом и Лицензиаром, Стороны будут пытаться решить путем переговоров.
              <br />
              8.3. В случае недостижения согласия путем переговоров, Стороны применяют досудебный порядок урегулирования споров, путем
              направления письменной претензии, содержащей заявляемые требования и подтверждающие документы.
              <br />
              8.4. В случае невозможности решить спор путем переговоров и/или досудебного урегулирования, спор передается на рассмотрение
              Арбитражного суда г. Кирова – в случае если Лицензиат – юридическое лицо, и в суд общей юрисдикции по месту нахождения
              Лицензиара – в случае если Лицензиат – физическое лицо.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('9. Персональные данные')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              9.1. Принимая условия настоящего Договора, Лицензиат, действуя свободно, своей волей и в своём интересе, даёт конкретное,
              информированное и сознательное согласие на обработку ООО « ГК «Белый Медведь», зарегистрированному по адресу 610050, город
              Киров, улица Московская, д. 199, этаж 2 ком 1, своих персональных данных, сбор статистики об IP-адресах и файлов Cookie, а
              также подтверждает факт ознакомления с Политикой в отношении обработки персональных данных (https://telebon.ru/privacy).
              <br />
              9.2. Лицензиат выражает своё согласие на обработку следующих категорий персональных данных: фамилия, имя, отчество, адрес
              электронной почты, контактные телефоны.
              <br />
              9.3. Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, включая сбор,
              запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
              (предоставление, доступ), блокирование, обезличивание, удаление, уничтожение персональных данных и совершение иных действий,
              предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»
              <br />
              9.4. Целями обработки персональных данных являются:
              <br />• Идентификации Пользователя, регистрация на Сайте и в Программном продукте.
              <br />• Использование Программного продукта и Сайта в соответствии с их назначением, заключение и исполнение договора.
              <br />• Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования
              сайта, обработка запросов и заявок от Пользователя.
              <br />• Обработки и получения платежей, оспаривания платежа. В целях дополнительной защиты от мошеннических действий указанные
              Пользователем персональные данные могут быть переданы платёжной системе, осуществляющей транзакции.
              <br />• Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с
              использованием Сайта и Программного продукта.
              <br />• Предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и
              иных сведений от имени Администрации сайта.
              <br />• Осуществления рекламной деятельности.
              <br />• Исполнение требований законодательства Российской Федерации.
              <br />
              9.5. Полученное согласие действует со дня заключения настоящего Договора и до достижения целей обработки персональных данных,
              если иное не предусмотрено законодательством Российской Федерации, и досрочно может быть отозвано Лицензиатом путем
              направления ООО «ГК «Белый Медведь» уведомления по адресу электронной почты: hello@telebon.ru
              <br />
              9.6. Условия обработки персональных данных, определенные в пунктах 10.1. - 10.5. настоящего Договора не распространяются на
              случай, когда установка Программного продукта осуществляется на техническом средстве (ЭВМ) Лицензиата. В данном случае
              Лицензиат самостоятельно несет ответственность за сбор, обработку и хранение персональных данных. В случае претензий со
              стороны третьих лиц и/или государственных органов к Лицензиату, Лицензиат обязуется компенсировать любые штрафы и/или ущерб от
              таких претензий.
              <br />
              9.7. В процессе заключения и исполнения настоящего Договора, Лицензиар запрашивает документы, подтверждающие правомерность
              деятельности Лицензиата – юридического лица, а также документы, подтверждающие личность Лицензиата – физического лица.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('10. Электронный документооборот')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              10.1. Стороны соглашаются признавать полученные (направленные) электронные документы, равнозначными аналогичным документам на
              бумажных носителях, заверенным соответствующими подписями и оттиском печатей Сторон, при выполнении условий, определенных
              настоящим разделом.
              <br />
              10.2. Стороны согласны обмениваться следующими электронными документами: договоры, дополнительные соглашениями и приложения к
              ним, счета, акты приема-передачи, акты выполненных работ/оказанных услуг, товарные накладные, акты сверки расчетов,
              счет-фактуры. Стороны оставляют за собой право в любой момент ввести в электронный документооборот любые иные документы, прямо
              не указанные в настоящем пункте, и применять при обмене такими документами правила, установленные Договором.
              <br />
              10.3. Стороны обязаны информировать друг друга о невозможности обмена документами в электронном виде, подписанными усиленной
              квалифицированной электронной подписью, в случае технического сбоя внутренних систем Сторон. В этом случае в период действия
              такого сбоя Стороны производят обмен документами на бумажном носителе с подписанием собственноручной подписью уполномоченного
              лица и оттиском печати Сторон с учетом положений п. 12.3 Договора.
              <br />
              10.4. Стороны оставляют за собой право изготавливать и обмениваться документами, предусмотренными данным разделом, на бумажных
              носителях, заверенными соответствующими подписями и оттиском печати Сторон.
              <br />
              10.5. При выставлении, направлении и обмене электронными документами Стороны руководствуются следующим порядком:
              <br />
              10.5.1. Направляющая Сторона формирует электронный документ в Системе ЭДО, подписывает его посредством усиленной
              квалифицированной электронной подписи и отправляет его получающей Стороне.
              <br />
              10.5.2. Получающая Сторона при получении документа в электронном виде проверяет действительность сертификата усиленной
              квалифицированной электронной подписи и сохраняет документ в Системе ЭДО.
              <br />
              10.5.3. Получающая Сторона в течение 5 (пяти) рабочих дней с момента получения документов, направляет направляющей Стороне
              через Оператора ЭДО извещение об их получении. Получающая Сторона, ознакомившись с документом, может совершить одно из
              следующих действий:
              <br /> • Подписать его посредством усиленной квалифицированной электронной подписи и отправить направляющей Стороне по Системе
              ЭДО – в том случае, если получающая Сторона согласна с содержанием документа.
              <br />• При несогласии с содержанием документа – отказать в подписании, указав причину несогласия, подписать отказ посредством
              усиленной квалифицированной электронной подписи и отправить направляющей Стороне.
              <br />
              10.5.4. Направляющая Сторона, получившая подписанный документ или отказ от подписания, проверяет действительность сертификата
              усиленной квалифицированной электронной подписи и сохраняет их в Системе ЭДО.
              <br />
              10.6. Оплата за услуги при передаче Электронного документа производится за счет средств Стороны, осуществляющей отправку
              электронного документа.
              <br />
              10.7. Передача Электронных документов через Систему ЭДО фиксируется протоколом передачи, автоматически формируемым Системой
              ЭДО, в котором отражается каждое действие с электронным документом на этапах его согласования и подписания усиленной
              квалифицированной электронной подписью. Стороны признают, что протокол передачи является достаточным доказательством факта
              получения электронных документов Стороной.
              <br />
              10.8. При использовании усиленной квалифицированной электронной подписи Стороны обязаны:
              <br />
              10.8.1. Обеспечивать конфиденциальность ключей электронных подписей, в частности не допускать использования принадлежащих им
              ключей электронных подписей без их согласия.
              <br />
              10.8.2. Уведомлять удостоверяющий центр, выдавший сертификат, и иных участников электронного взаимодействия о нарушении
              конфиденциальности ключа электронной подписи в течение не более чем 1 (одного) рабочего дня со дня получения информации о
              таком нарушении.
              <br />
              10.8.3. Не использовать ключ электронной подписи при наличии оснований полагать, что конфиденциальность данного ключа
              нарушена.
              <br />
              10.8.4. Использовать сертифицированные в соответствии с требованиями законодательства Российской Федерации средства
              электронной подписи для создания и проверки усиленной квалифицированной электронной подписи, создания ключей электронных
              подписей и ключей их проверки.
              <br />
              10.9. Усиленная квалифицированная электронная подпись признается действительной до тех пор, пока решением суда не установлено
              иное, при одновременном соблюдении следующих условий:
              <br />
              10.9.1. Сертификат создан и выдан аккредитованным удостоверяющим центром, аккредитация которого действительна на день выдачи
              указанного сертификата.
              <br />
              10.9.2. Сертификат действителен на момент подписания электронного документа (при наличии достоверной информации о моменте
              подписания электронного документа) или на день проверки действительности указанного сертификата, если момент подписания
              электронного документа не определен.
              <br />
              10.9.3. Имеется положительный результат проверки принадлежности владельцу сертификата, с помощью которой подписан электронный
              документ, и подтверждено отсутствие изменений, внесенных в этот документ после его подписания. При этом проверка
              осуществляется с использованием сертифицированных средств электронной подписи, и с использованием Сертификата лица,
              подписавшего электронный документ.
              <br />
              10.9.4. Усиленная квалифицированная электронная подпись используется с учетом ограничений, содержащихся в сертификате лица,
              подписывающего электронный документ (если такие ограничения установлены).
              <br />
              10.10. Каждая из Сторон предоставляет заверения и гарантии, что лица, подписывающие документы от имени соответствующей Стороны
              с использованием усиленной квалифицированной электронной подписи, имеют на подписание таких документов соответствующие
              полномочия.
              <br />
              10.10.1. Стороны вправе запрашивать друг у друга документы, подтверждающие полномочия лица, подписавшего Электронный документ,
              на их подписание.
              <br />
              10.10.2. В случае отзыва доверенности, либо иных случаев утраты или изменения объема прав лица, обладающего правом подписи
              документов, соответствующая Сторона должна в тот же день известить об этом другую Сторону в порядке, предусмотренном
              Договором, в противном случае такая Сторона не вправе в дальнейшем ссылаться на подписание документов неуполномоченным лицом.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('11. Заключительные положения')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              11.1. О любых изменениях в настоящий Договор Лицензиар уведомляет Лицензиата путем направления соответствующего уведомления на
              электронную почту последнего или через Личный кабинет.
              <br />
              11.2. Документы, которыми Стороны обмениваются в процессе исполнения настоящего Договора и отправленные по факсу и/или
              электронной почте имеют силу оригиналов до получения таких документов на бумажном носителе и признаются Сторонами в качестве
              оригиналов документов.
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={700}
              color={'black'}
            >
              {t('12. Адрес и реквизиты Лицензиара')}
            </NewText>
            <Padding $size='16px' />
            <NewText
              $customSize={TextCustomType.T14}
              fontWeight={400}
              color={'black'}
            >
              ООО «Группа компаний «Белый Медведь»
              <br />
              610050, г. Киров, улица Московская, дом 199, этаж 2
              <br />
              ИНН 4345410051
              <br />
              КПП 434501001
              <br />
              Тел: 8 812 507-63-33
              <br />
              <br />
              Банковские реквизиты
              <br />
              ПАО "НОРВИК БАНК"
              <br />
              <br />
              Расчетный счет: 40702810800350161643
              <br />
              Корреспондентский счет: 30101810745374525845
              <br />
              БИК: 044525845
            </NewText>
          </List>
        )}
      </FlexWithAlign>
      <NewButton
        type='button'
        typeBtn='black'
        fullWidth
        onClick={closeModal}
      >
        {t('Хорошо, спасибо')}
      </NewButton>
    </RegistrationPolicyWrapper>
  );
};

export default RegistrationPolicy;
