// import { useMemo } from 'react';

// import { useFormik } from 'formik';

import { useEffect, useState } from 'react';

import {
  CloseBtn,
  Filial,
  FilialsContanier,
  Form,
  LoginAvatar,
  LoginName,
  LoginNameContainer,
  ModalContent,
  Status,
  StatusDot,
  Wrapper
} from './ProfileExitModal.styled';

import { firstTwoLetters } from '../../../../../pages/MessengerPage/lib/messenger.helper';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewSearchInput from '../../../../../shared/new-ui/NewSearchInput/ui/NewSearchInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { Avatar, AvatarRounded, AvatarSize, ModalSize, ModalType } from '../../../../../shared/ui';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { IService } from '../../../../../store/redux/subproducts/subproducts.interface';
import { MODAL_GAP } from '../../../../../styles';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

interface IProps {
  modalPayload: IService;
  closeModal: () => void;
}

const ProfileExitModal = (props: IProps) => {
  const { closeModal } = props;
  const { openModal } = useActions();
  const { data: activeFilial } = useFilialQuery();
  const activeFilialArray = [activeFilial];
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredFilials, setFilteredFilials] = useState(activeFilialArray);
  const { profileUser } = useTypedSelector((state) => state.user);

  const logoutHandler = (id: string) => {
    openModal({
      modalName: 'CONFIRM_NEW_DELETE',
      actionName: 'PROFILE_EXIT',
      modalType: ModalType.TOP,
      modalPayload: { id: id },
      modalSize: ModalSize.CONFIRM_DELETE
    });
  };
  const formatAddress = (input: string) => {
    const parts = input.split(';');

    const city = parts[0];
    const street = parts[1];
    const houseNumber = parts[2];
    const apartmentNumber = parts[3];

    return `${city}, ул. ${street}, д ${houseNumber}, кв ${apartmentNumber}`;
  };

  useEffect(() => {
    if (searchValue.trim() !== '') {
      setFilteredFilials(
        activeFilialArray.filter((filial) => filial?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()))
      );
    } else {
      setFilteredFilials(activeFilialArray);
    }
  }, [searchValue]);

  return (
    <Wrapper>
      <Form>
        <FlexWithAlign
          className='form-wrapper'
          $gap={'15px'}
          $column
        >
          <FlexWithAlign
            className='top-wrapper'
            $gap={'23px'}
            $align={'center'}
          >
            <CloseBtn onClick={closeModal}>
              <IconNew name={EIcon.blackclose} />
            </CloseBtn>
            <NewText
              $tag={TextType.H1}
              fontWeight={600}
              className='title'
            >
              Смена филиала
            </NewText>
          </FlexWithAlign>
          <ModalContent>
            <NewSearchInput
              className='search'
              value={searchValue}
              name='filial-search'
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder='Поиск...'
            ></NewSearchInput>
            <FilialsContanier>
              {activeFilialArray
                .filter((filial) => filteredFilials.find((activeFilial) => filial?.id == activeFilial?.id))
                .map((filial) => (
                  <Filial key={filial?.id}>
                    <Status className='active-status'>
                      <StatusDot className='active-dot'></StatusDot>
                      <NewText
                        className='status-text'
                        $customSize={TextCustomType.T12}
                      >
                        Активен
                      </NewText>
                    </Status>
                    <NewText
                      className='filial-name'
                      $customSize={TextCustomType.T16}
                      color='title'
                      fontWeight={600}
                    >
                      {filial?.name}
                    </NewText>
                    <NewText
                      className='filial-address'
                      $customSize={TextCustomType.T12}
                      color='grey'
                      fontWeight={400}
                    >
                      {filial?.address && formatAddress(filial?.address)}
                    </NewText>
                  </Filial>
                ))}
            </FilialsContanier>
          </ModalContent>
          <FlexWithAlign
            $gap={MODAL_GAP}
            $justify='space-between'
          >
            <LoginNameContainer>
              <LoginAvatar>
                {profileUser?.avatar ? (
                  <Avatar
                    size={AvatarSize.M}
                    rounded={AvatarRounded.FULL}
                    avatar={profileUser?.avatar}
                  />
                ) : (
                  <Avatar
                    size={AvatarSize.M}
                    background={'greyGradient'}
                    rounded={AvatarRounded.FULL}
                  >
                    <NewText
                      $customSize={TextCustomType.T28}
                      fontWeight={400}
                      color={'avatarGrey'}
                    >
                      {firstTwoLetters(profileUser.fio)}
                    </NewText>
                  </Avatar>
                )}
              </LoginAvatar>
              <LoginName>
                <NewText
                  className='system'
                  $customSize={TextCustomType.T10}
                  color='grey'
                  fontWeight={400}
                >
                  {'Вы вошли в систему как'.toUpperCase()}
                </NewText>
                <NewText
                  className='profile-fio'
                  $customSize={TextCustomType.T14}
                  color='title'
                  fontWeight={400}
                >
                  {profileUser?.fio}
                </NewText>
              </LoginName>
            </LoginNameContainer>
            <NewButton
              className={'cancel'}
              onClick={logoutHandler}
            >
              Выход
            </NewButton>
          </FlexWithAlign>
        </FlexWithAlign>
      </Form>
    </Wrapper>
  );
};

export default ProfileExitModal;
