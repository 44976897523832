export const NewMinus = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.2075 6.78578V7.23378C14.2075 7.27645 14.1222 7.31911 13.9515 7.36178C13.7809 7.40445 13.5889 7.42578 13.3755 7.42578H2.55954C2.34621 7.42578 2.15421 7.40445 1.98354 7.36178C1.81287 7.31911 1.72754 7.27645 1.72754 7.23378V6.78578C1.72754 6.74311 1.81287 6.70045 1.98354 6.65778C2.15421 6.61511 2.34621 6.59378 2.55954 6.59378H13.3755C13.5889 6.59378 13.7809 6.61511 13.9515 6.65778C14.1222 6.70045 14.2075 6.74311 14.2075 6.78578Z'
        fill='currentColor'
      />
    </svg>
  );
};
