export const getRouteAuth = () => '/auth';
export const getRouteRegister = () => '/registration';
export const getRouteRegisterConfirm = () => '/confirm/:confirmKey';
export const getRouteNewPassword = () => '/password/update/:refreshToken';
export const getRouteSupport = () => '/support';
export const getRouteOnlineRecording = () => '/online-recording';
export const getRouteTelegramBotInfo = () => '/telegram-bot-info';
export const getRouteResetPassword = () => '/password/recovery';
export const getRouteSussessPasword = () => '/password/success';

export const getRouteClient = () => '/clients';
export const getRouteTaskbook = () => '/taskbook/:taskPageType';
export const getRouteCallDialog = () => '/call/dialog/inidletime';
export const getRouteCallProduct = () => '/call/product';
export const getRouteEmployeesSummary = () => '/employees';
export const getRouteEmployeesTimetable = () => '/timetable';
export const getRouteAnalytics = () => '/analytics/:analyticsId';
export const getRoutePortalRedirectPayment = () => '/portalsettings/redirectpaymentpage';
export const getRouteNotFound = () => '*';
export const getRouteMessenger = () => '/messenger/:roomId';
export const getRouteMessengerList = () => '/messenger-list';
export const getRouteMessengerChat = () => '/messenger-list/:roomId';
export const getRouteUserRecording = () => '/user-recording/:date/:time/:master/:service/:tarif';
export const getRouteUserRecordingAll = () => '/user-recording-all/:id/:filial_id';
export const getMoreMenu = () => '/more';

//new routes
export const getRouteSales = () => '/sales/new';
export const getRouteNewSales = () => '/sales/new';
export const getRouteSalesCashRegisters = () => '/sales/cash-registers';
export const getRouteSalesTransactions = () => '/sales/transactions';

export const getRouteSuccesSales = () => '/sales/success';
export const getRouteCabinets = () => '/cabinets';
export const getRouteCabinetsAdd = () => '/cabinets/create';
export const getRouteCabinetsEdit = () => '/cabinets/edit/:cabinetId';

export const getRouteServiceCategory = () => '/service-category';
export const getRouteServiceAdd = () => '/service/add';
export const getRouteServiceEdit = () => '/service/:categoryId/edit/:serviceId';
export const getRouteSettings = () => '/settings';
export const getRoutePortal = () => '/portal';
export const getRoutePortalRedirect = () => '/portalsettings/tarif';
export const getRouteProfile = () => '/profile';
export const getRouteProfileLogo = () => '/profile/logo';
export const getRoutePersonalArea = () => '/personal-area';
export const getRouteBusinessSettings = () => '/business-settings';
export const getRouteBusinessSettingsMain = () => '/business-settings/main';
export const getRouteBusinessSettingsCategory = () => '/business-settings/category';
export const getRouteBusinessSettingsLogo = () => '/business-settings/logo';
export const getRouteBusinessSettingsOperatingMode = () => '/business-settings/operating-mode';
export const getRouteSubscribe = () => '/subscribe';
export const getRouteLicence = () => '/licence';
export const getRouteUserAgreement = () => '/user-agreement';
export const getRoutePrivacyPolicy = () => '/privacy-policy';

export const getRouteCashRegister = () => '/cash-registers';
export const getRouteProducts = () => '/products';
export const getRouteProductCreate = () => '/products/create';
export const getRouteCalendarCabinet = () => '/calendar/cabinet';
export const getRouteListCabinet = () => '/list/cabinet';
export const getRouteCalendarEmployee = () => '/calendar/employee';
export const getRouteCalendarSettings = () => '/calendar/settings';

export const getRouteListEmployee = () => '/list/employee';
export const getRouteEditClient = () => '/clients/edit';
export const getRouteCreateClient = () => '/clients/create';
export const getRouteImportClient = () => '/clients/import';
export const getRouteEmployeeCreate = () => '/employees/create';
export const getRouteEmployeeEdit = () => '/employees/edit/:employeeId';
export const getRoutePersonalAreaSettings = () => '/personal-area/settings';
export const getRouteProfileSecurity = () => '/personal-area/security';
export const getRouteExitAccount = () => '/personal-area/exit-account';
export const getRoutePaymentsAndSales = () => '/payments-and-sales';
export const getRoutePaymentsSettings = () => '/payments-settings';

export const getRouteCashRegisterCreate = () => '/cash-register/create';
export const getRouteCashRegisterEdit = () => '/cash-register/edit/:cashRegisterId';

export const getRouteTransactionsAll = () => '/transactions-all';
export const getRouteToSite = () => '/personal-site';
