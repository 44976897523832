import { FC, useCallback, useEffect, useState } from 'react';
import React from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { CloseButton, CropWrapper, ModalBottomWrapper, ModalTopWrapper, ModalWrapper } from './CropImageModal.styled';

import { useActions } from '../../../../../shared/lib/hooks/useActions';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { getCroppedImg } from '../../../../../utils/cropImageUtils';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

const AspectRatio = 2 / 1;

interface ICropImageModal {
  closeModal: () => void;
  modalPayload?: { image: File };
}
const CropImageModal: FC<ICropImageModal> = ({ closeModal, modalPayload }) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const [imageFile, setImageFile] = useState<File | null>(modalPayload?.image || null);
  const [imageFileSrc, setImageFileSrc] = useState<string | undefined>(undefined);

  const { setImages } = useActions();
  const { t } = useTranslation();

  useEffect(() => {
    if (modalPayload?.image) {
      setImageFileSrc(URL.createObjectURL(modalPayload?.image));
    }
  }, []);
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixelData: Area) => {
      setCroppedAreaPixels(croppedAreaPixelData);
    },
    [setCroppedAreaPixels]
  );
  const onCropChange = useCallback(
    (crop: { x: number; y: number }) => {
      setCrop(crop);
    },
    [setCrop]
  );

  const onZoomChange = useCallback(
    (zoom: number) => {
      setZoom(zoom);
    },
    [setZoom]
  );

  const handleCrop = async () => {
    try {
      if (imageFile && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(imageFile, croppedAreaPixels);
        if (croppedImage) {
          setImages(croppedImage);
          closeModal();
        }
      }
      // onCropComplete(croppedImage); // Отправить обрезанное изображение в Base64
    } catch (error) {
      console.error('Ошибка при обрезке изображения:', error);
    }
  };

  return (
    <>
      {imageFile && (
        <ModalWrapper
          $column
          $justify='space-between'
        >
          <ModalTopWrapper
            $align='center'
            $justify='space-between'
          >
            <FlexWithAlign
              $gap='16px'
              $align='center'
            >
              <CloseButton
                typeBtn='close'
                onClick={closeModal}
              >
                <IconNew name={EIcon.close} />
              </CloseButton>
              <NewText
                color='white'
                $tag={TextType.H2}
                fontWeight={600}
                $customSize={TextCustomType.T28}
              >
                {t('Настройка изображения')}
              </NewText>
            </FlexWithAlign>
            <NewButton onClick={handleCrop}>{t('Добавить')}</NewButton>
          </ModalTopWrapper>
          <CropWrapper $justify='center'>
            <Cropper
              image={imageFileSrc}
              aspect={AspectRatio}
              crop={crop}
              // objectFit={'cover'}
              zoom={zoom}
              // rotation={rotation}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              // onRotationChange={setRotation}
            />
          </CropWrapper>
          <ModalBottomWrapper $justify='center'>
            <NewText
              color='white'
              $customSize={TextCustomType.T16}
            >
              {t('Отрегулируйте размер сетки, чтобы обрезать изображение.')}
            </NewText>
          </ModalBottomWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default CropImageModal;
