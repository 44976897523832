import styled from 'styled-components';

import themelight from './providers/ThemeProvider/ui/theme';

import { FlexWithAlign } from '../utils/styleUtils';

export const AppContainer = styled.div`
  background: ${themelight.newColor.bg};
  .active-time-slot {
    .fc-timegrid-event {
      box-shadow: none;
      border: none;
      background-color: transparent;
      padding-right: 8px;
      margin-bottom: 0;
      .hiddenInfo {
        box-shadow: 0px 23px 38px 0px #00000029;
        background-color: #fff;
        border-radius: 8px;
        border: 1px dashed #bcbcc2;
        min-height: fit-content;
        & > div {
          // box-shadow: 0px 23px 38px 0px #00000029;
          // background-color: #fff;
          // border-radius: 8px;
          // border: 1px dashed #bcbcc2;
        }
        .heading {
          font-size: 12px;
        }
      }
      &.activeEvent {
        .fc-event-main {
          // box-shadow: 0px 23px 38px 0px #00000029;
          background-color: #fff;
          border-radius: 8px;
          // border: 1px dashed #bcbcc2;
          & > div {
            // box-shadow: 0px 23px 38px 0px #00000029;
            // background-color: #fff;
            // border-radius: 8px;
            // border: 1px dashed #bcbcc2;
          }
          .heading {
            color: #000;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const LoadingAnimationStart = styled(FlexWithAlign)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  inset: 0;
  z-index: 99;
  background: ${(props) => props.theme.color.base01};
  .wrapp {
    position: fixed;
    width: 100vw;
    background: ${(props) => props.theme.color.base01};

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    -webkit-perspective: 30px;
    -webkit-transform: translateX(0px);
    -webkit-animation: wrapp 400ms 800ms ease-in forwards;

    -moz-perspective: 30px;
    -moz-transform: translateX(0px);
    -moz-animation: wrapp 400ms 800ms ease-in forwards;

    -ms-perspective: 30px;
    -ms-transform: translateX(0px);
    -ms-animation: wrapp 400ms 800ms ease-in forwards;

    perspective: 30px;
    transform: translateX(50px);
    animation: wrapp 400ms 800ms ease-in forwards;
  }
  .text {
    > svg {
      position: absolute;
      top: 50%;
      opacity: 1;
      left: 50%;
      width: 0px;
      height: 60px;
      min-height: 60px;
      margin: -30px 0 0 -240px;
      overflow: hidden;
      -webkit-transform: translateX(0px);
      -webkit-animation: text 400ms 800ms linear forwards;

      -moz-transform: translateX(0px);
      -moz-animation: text 400ms 800ms linear forwards;

      -ms-transform: translateX(0px);
      -ms-animation: text 400ms 800ms linear forwards;

      transform: translateX(0px);
      animation: text 400ms 800ms linear forwards;
    }
  }

  .logo {
    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45px;
      height: 90px;
      margin: -45px 0 0 -65px;
      background-color: #fff;
      -webkit-transform: translateX(0px) scale(2);
      -webkit-animation: logo 500ms 300ms ease-out forwards;

      -moz-transform: translateX(0px) scale(2);
      -moz-animation: logo 500ms 300ms ease-out forwards;

      -ms-transform: translateX(0px) scale(2);
      -ms-animation: logo 500ms 300ms ease-out forwards;

      transform: translateX(0px) scale(2);
      animation: logo 500ms 300ms ease-out forwards;
    }
  }

  @-webkit-keyframes logo {
    from {
      -webkit-transform: translateX(0px) scale(2);
    }
    to {
      -webkit-transform: translateX(0px) scale(1);
    }
  }
  @-webkit-keyframes text {
    from {
      width: 0px;
      opacity: 0;
      -webkit-transform: translateX(0px);
    }
    60% {
      width: 0px;
      opacity: 0;
    }
    to {
      width: 200px;
      -webkit-transform: translateX(240px);
      opacity: 1;
    }
  }
  @-webkit-keyframes wrapp {
    from {
      -webkit-transform: translateX(50px);
    }
    to {
      -webkit-transform: translateX(-15%);
    }
  }
  @-moz-keyframes logo {
    from {
      -moz-transform: translateX(0px) scale(2);
    }
    to {
      -moz-transform: translateX(0px) scale(1);
    }
  }
  @-moz-keyframes text {
    from {
      width: 0px;
      opacity: 0;
      -moz-transform: translateX(0px);
    }
    60% {
      width: 0px;
      opacity: 0;
    }
    to {
      width: 200px;
      -moz-transform: translateX(240px);
      opacity: 1;
    }
  }
  @-moz-keyframes wrapp {
    from {
      -moz-transform: translateX(50px);
    }
    to {
      -moz-transform: translateX(-15%);
    }
  }

  @-ms-keyframes logo {
    from {
      -ms-transform: translateX(0px) scale(2);
    }
    to {
      -ms-transform: translateX(0px) scale(1);
    }
  }
  @-ms-keyframes text {
    from {
      width: 0px;
      opacity: 0;
      -ms-transform: translateX(0px);
    }
    60% {
      width: 0px;
      opacity: 0;
    }
    to {
      width: 200px;
      -ms-transform: translateX(240px);
      opacity: 1;
    }
  }
  @-ms-keyframes wrapp {
    from {
      -ms-transform: translateX(50px);
    }
    to {
      -ms-transform: translateX(-15%);
    }
  }
  @keyframes logo {
    from {
      transform: translateX(0px) scale(2);
    }
    to {
      transform: translateX(0px) scale(1);
    }
  }
  @keyframes text {
    from {
      width: 0px;
      transform: translateX(0px);
      opacity: 0;
    }
    60% {
      width: 0px;
      opacity: 0;
    }
    to {
      width: 200px;
      transform: translateX(240px);
      opacity: 1;
    }
  }
  @keyframes wrapp {
    from {
      transform: translateX(50px);
    }
    to {
      transform: translateX(-15%);
    }
  }
`;
