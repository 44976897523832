import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { DURATION, Z_INDEX } from '../../../../styles';
import { NEW_INPUT_BORDER_RADIUS, NEW_INPUT_HEIGHT } from '../../../../styles/newAssets';
import { closeDropdownMenu, FlexWithAlign, openDropdownMenu } from '../../../../utils/styleUtils';
import { IInputStyles } from '../../NewInput/ui/NewInput.styled';

export const Label = styled.span`
  padding: 0 6px;
  font-size: 10px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  line-height: 1.4;
  top: 0;
  transform: translateY(-50%);
  transition-property: transform, font, height, color, letter-spacing, top;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  left: 11px;
  text-overflow: ellipsis;
  display: block;
  user-select: none;
  color: ${themelight.newText.textGrey};
  pointer-events: none;
  text-transform: uppercase;
  background-color: transparent;
  z-index: 1;
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 6.5px;
    left: -3px;
    right: -3px;
    width: calc(100% + 6px);
    height: 100%;
    max-height: 2px;
    background-color: ${themelight.newColor.base01};
    transition: all 0.2s ease-in-out;
  }
  &.active {
    &::after {
      max-height: 2px;
      top: 6px;
      transition: all 0.15s ease-in-out;
    }
  }
`;

export const DropDownWrapper = styled.div<{
  $width?: string;
}>`
  position: relative;
  flex: 1;
  width: ${(props) => (props.$width ? props.$width : '100%')};
  max-width: ${(props) => (props.$width ? props.$width : '100%')};
  border-radius: 5px;
  height: ${NEW_INPUT_HEIGHT};
  min-height: ${NEW_INPUT_HEIGHT};
  max-height: ${NEW_INPUT_HEIGHT};
  & > div {
    background: ${(props) => props.theme.newColor.base01};
    border-radius: 5px;
  }
  &.error {
    height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
    min-height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
    max-height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
  }

  .dropdownArrow {
    padding: 4px 12px;
    color: ${themelight.newColor.black02};
  }
  .custom-select__control {
    box-shadow: none;
    border: none !important;
    &:after {
      transition-property: color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      color: ${(props) => props.theme.newColor.base08};
      position: absolute;
      inset: 0;
      content: '';
      border-radius: 5px;
      border: 1px solid currentColor;
      pointer-events: none;
    }
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    background: transparent !important;
    position: relative;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    outline: none !important;
    height: ${NEW_INPUT_HEIGHT};
    min-height: ${NEW_INPUT_HEIGHT};
    max-height: ${NEW_INPUT_HEIGHT};
    visibility: visible;
    align-content: center;
    text-align: inherit;
    color: ${(props) => props.theme.color.text01};
    transition-property: box-shadow, background;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
  }
  .custom-select__control--is-disabled {
    background: ${(props) => props.theme.newColor.base05} !important;
    .custom-select__single-value {
      color: ${(props) => props.theme.newColor.grey06};
    }
    .dropdownArrow {
      color: ${(props) => props.theme.newColor.grey06};
    }
  }
  .custom-select__control--is-focused {
  }
  .css-tj5bde-Svg {
    right: 0;
    top: 21px;
  }
  .custom-select__value-container {
    cursor: pointer;
    padding: 0 0 0 1rem;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
    border-width: 0;
    border-radius: inherit;
    background: none;
    caret-color: currentColor;
    word-break: keep-all;
  }
  &.with-icon {
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    .custom-select__control {
      height: 64px;
      min-height: 64px;
      max-height: 64px;
    }
    .custom-select__value-container {
      padding: 12px 0 12px 12px;
    }
  }
  .custom-select__single-value {
    display: flex;
    gap: 16px;
    align-items: center;
    text-overflow: initial;
    width: 100%;
    line-height: 20px;
    margin: 0;
    font-weight: 700;
    color: ${(props) => props.theme.newColor.primary};
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .custom-select__input-container {
    color: #999aa5 !important;
  }

  .custom-select__indicator-separator {
    display: none !important;
  }

  .custom-select__indicators {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.newColor.black02};
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition-property: opacity, transform;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    width: 2.75rem;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .custom-select__multi-value {
    background-color: ${(props) => props.theme.color.base03};
  }

  .custom-select__menu {
    background-color: ${(props) => props.theme.color.elevation01};
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    isolation: isolate;
    pointer-events: auto;
    color: ${(props) => props.theme.text.text01} !important;
    position: absolute;
    border: none !important;
    font-size: 13px;
    right: 0;
    overflow-x: hidden;
    z-index: ${Z_INDEX};
    transform-origin: top;
    animation: ${openDropdownMenu} ${DURATION} ease;
    &.menu--close {
      animation: ${closeDropdownMenu} ${DURATION} ease;
    }
  }
  .custom-select__control--menu-is-open .custom-select__indicators {
    transform: rotate(180deg);
  }
  .css-mohuvp-dummyInput-DummyInput {
    position: absolute;
  }
  .custom-select__option {
    color: ${(props) => props.theme.newColor.primary};
    transition-property: background;
    transition-timing-function: ease-in-out;
    transition-duration: ${DURATION};
    border-radius: ${NEW_INPUT_BORDER_RADIUS};
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
    &.custom-select__option--is-focused {
      background: transparent;
      outline: none !important;
    }
    &.custom-select__option--is-selected {
      background-color: ${(props) => props.theme.newColor.base08};
    }
    &:hover {
      background-color: ${(props) => props.theme.newColor.base08};
    }
  }

  &.focused {
    .custom-select__control {
      box-shadow: none;
      &:after {
        border-width: 2px;
        color: ${(props) => props.theme.newColor.primary};
        box-shadow: none;
      }
    }
  }
  &.error {
    .custom-select__control {
      &:after {
        color: ${(props) => props.theme.newColor.error};
      }
    }
  }

  .custom-select__placeholder {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.95px;
    color: ${(props) => props.theme.newText.textGrey};
  }
  &.focused .custom-select__placeholder {
    margin: 0;
  }
`;

export const DropDownIconWrap = styled.span`
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${themelight.newColor.greyF9};
  color: ${themelight.newColor.grey08};
  flex-shrink: 0;
  svg {
    max-width: 24px;
  }
`;

export const LabelWrap = styled(FlexWithAlign)``;

export const OptionWrap = styled(FlexWithAlign)`
  width: 100%;
`;

export const ErrorText = styled.p<IInputStyles>`
  position: relative;
  white-space: pre-wrap;
  display: block;
  color: ${themelight.newColor.error};
  word-wrap: break-word;
  animation: present 1s infinite;
  left: 5px;
  font-size: 12px;
`;
