import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { getItemName, IConfirmDeleteModalProps } from './NewCommonConfirmDelete.data';
import { ButtonsWrap, ModalContent, ModalWrapper, TextWrap } from './NewCommonConfirmDelete.styled';

import {
  getRouteCabinets,
  getRouteClient,
  getRouteEmployeesSummary,
  getRoutePaymentsSettings,
  getRouteProducts
} from '../../../../../shared/const';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { IUser } from '../../../../../shared/types/user/user.interface';
import { ICabinet } from '../../../../../store/redux/cabinets/cabinets.interface';
import { useDeleteCabinet } from '../../../../../store/redux/cabinets/hooks/useCabinetsMutation';
import { useCashRegisterDelete } from '../../../../../store/redux/cash-register/hooks/useCashRegisterMutation';
import { IClient } from '../../../../../store/redux/clients/clients.interface';
import { useDeleteClient } from '../../../../../store/redux/clients/hooks/useClientsMutation';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { IActionName } from '../../../../../store/redux/modal/modal.interface';
import { useDeletePayment } from '../../../../../store/redux/payments/hooks/usePaymentsMutation';
import { useDeleteProduct } from '../../../../../store/redux/products/hooks/useProductsMutation';
import { useDeleteSaleProduct } from '../../../../../store/redux/saleproducts/hooks/useSaleProductsMutation';
import { ISaleproduct } from '../../../../../store/redux/saleproducts/saleproducts.interface';
import { useDeleteService } from '../../../../../store/redux/subproducts/hooks/useSubproductsMutation';
import { useDeleteUser } from '../../../../../store/redux/user/hooks/useUsersMutation';
import { sendMessage, setChatRoomId } from '../../../../../store/redux/websocket/chat-reducer';
import { IconNew, EIcon } from '../../../../icons/medium-new-icons/icon';

const NewCommonConfirmDelete: FC<IConfirmDeleteModalProps> = (props) => {
  const { modalPayload, actionName, closeModal } = props;
  const { setInitialState, logout, setForPaymentLesson } = useActions();
  const { mutateAsync: deleteProductFn } = useDeleteProduct();
  const { mutateAsync: deleteServiceFn } = useDeleteService();
  const { mutateAsync: deleteSaleProduct } = useDeleteSaleProduct();
  const { mutateAsync: deleteClient } = useDeleteClient();
  const { mutateAsync: deleteUserFn } = useDeleteUser();
  const { mutateAsync: deleteCabinetFn } = useDeleteCabinet();
  const { mutateAsync: deletePaymentFn } = useDeletePayment();
  const { mutateAsync: deleteCashPaymentFn } = useCashRegisterDelete();
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const itemName = getItemName(actionName);

  const deleteProduct = async (productid: string) => {
    const res = await deleteProductFn(productid);
    if (res.status === 'ok') {
      toast.success('Категория успешно удалена');
      nav('/service-category');
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const deleteSubProduct = async (productid: string, subproductid: string) => {
    const res = await deleteServiceFn({ productid, subproductid });
    if (res?.status === 'ok') {
      nav('/service-category');
      toast.success(t('Услуга успешно удалена'));
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const deleteSaleProductFn = async (saleproductid: string) => {
    const res = await deleteSaleProduct(saleproductid);
    if (res?.status == 'ok') {
      await nav(getRouteProducts());
      toast.success('Продукт успешно удален');
    }
    if (res?.status !== 'ok') {
      toast.success('При удалении продукта произошла ошибка');
    }

    await closeModal?.();
    await nav(getRouteProducts());
  };

  const onDeleteProducts = async (saleproducts: ISaleproduct[]) => {
    try {
      const deletePromises = saleproducts.map((item) => deleteSaleProduct(item.id ? item.id : ''));

      const responses = await Promise.all(deletePromises);

      const allDeletedSuccessfully = responses.every((res) => res?.status === 'ok');

      if (allDeletedSuccessfully) {
        toast.success('Продукты успешно удалены');
        modalPayload.setSelectedItems([]);
        modalPayload.setSelectAll(false);
        closeModal?.();
      } else {
        toast.error('Некоторые продукты не были удалены, произошла ошибка');
      }
    } catch (error) {
      toast.error('Произошла ошибка при удалении продуктов');
    }
  };

  const { data: activeFilial } = useFilialQuery();
  const deleteChat = async (chat_id: string | undefined) => {
    if (chat_id) {
      await dispatch(sendMessage(JSON.stringify({ type: 'delete_chat', data: { chat_id } })) as any);
      await closeModal?.();
      await nav('/messenger/telebot');
      if (activeFilial) {
        setChatRoomId('telebot');
        dispatch(sendMessage(JSON.stringify({ type: 'get_records', data: { filial_id: activeFilial.id } })) as any);
      }
    }
  };

  const deleteClientFn = async (client: IClient) => {
    const res = await deleteClient(client.id);
    if (res?.status === 'ok') {
      await nav(getRouteClient());
      toast.success('Клиент успешно удален');
    } else {
      toast.error('Клиент не удален, произошла ошибка');
    }
    await closeModal?.();
  };

  const deleteSales = async () => {
    await setInitialState();
    setForPaymentLesson(null);
    closeModal?.();
    // modalPayload.setIsView(0);
  };

  const deleteUser = async (user: IUser) => {
    const res = await deleteUserFn(user.id);
    if (res?.status === 'ok') {
      nav(getRouteEmployeesSummary());
      toast.success('Сотрудник успешно удален');
    } else {
      toast.error('Ошибка при удалении сотрудника');
    }
    closeModal?.();
  };

  const deleteCabinet = async (cabinet: ICabinet) => {
    const res = await deleteCabinetFn({ idcabinet: cabinet.id, idfilial: cabinet.filial });
    if (res?.Status === 'ok') {
      nav(getRouteCabinets());
      toast.success('Ресурс успешно удален');
    } else {
      toast.error('Ошибка при удалении ресурса');
    }
    closeModal?.();
  };

  const deletePayment = async (paymentId: string) => {
    const res = await deletePaymentFn(paymentId);
    if (res.status === 'ok') {
      toast.success(t('Способ оплаты удален'));
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const deleteCashRegister = async (cashRegisterId: string) => {
    const res = await deleteCashPaymentFn(cashRegisterId);
    if (res.status === 'ok') {
      toast.success(t('Касса удалена'));
    } else {
      toast.error(res.description);
    }
    nav(getRoutePaymentsSettings());
    closeModal?.();
  };

  const exitAccount = async () => {
    logout();
    const res = await queryClient.invalidateQueries();
  };

  const deleteItem = (action?: IActionName, modalPayload?: any) => {
    switch (action) {
      case 'SUBPRODUCT':
        if (modalPayload.id && modalPayload.idparents) {
          deleteSubProduct(modalPayload.idparents, modalPayload.id);
        }
        break;
      case 'PRODUCT':
        if (modalPayload.id) {
          deleteProduct(modalPayload.id);
        }
        break;
      case 'SALEPRODUCT':
        if (modalPayload.id) {
          deleteSaleProductFn(modalPayload.id);
        }
        break;
      case 'SALEPRODUCTS':
        if (modalPayload) {
          onDeleteProducts(modalPayload.selectedItems);
        }
        break;
      case 'CHAT':
        if (modalPayload) {
          deleteChat(modalPayload);
        }
        break;
      case 'CLIENT':
        if (modalPayload) {
          deleteClientFn(modalPayload);
        }
        break;
      case 'SALES':
        if (modalPayload) {
          deleteSales();
        }
        break;
      case 'EMPLOYEE':
        if (modalPayload) {
          deleteUser(modalPayload);
        }
        break;
      case 'CABINET':
        if (modalPayload) {
          deleteCabinet(modalPayload);
        }
        break;
      case 'PAYMENT':
        if (modalPayload.id) {
          deletePayment(modalPayload.id);
        }
        break;
      case 'CASH_REGISTER':
        if (modalPayload.id) {
          deleteCashRegister(modalPayload.id);
        }
        break;
      case 'PROFILE_EXIT':
        exitAccount();
        break;
      default:
        return;
    }
  };

  return (
    <ModalWrapper
      $column
      $align={'center'}
      $gap={'24px'}
    >
      <span className='flex'>
        <IconNew name={actionName == 'SALES' ? EIcon.warningRound : EIcon.warning} />
      </span>
      <ModalContent
        $column
        $gap={'28px'}
        $align={'center'}
      >
        <TextWrap
          $column
          $gap={'8px'}
          $align={'center'}
        >
          <NewText
            $tag={TextType.H2}
            color='primary'
            $customSize={TextCustomType.T28}
          >
            {actionName == 'SALES'
              ? t(`Отменить продажу?`)
              : actionName == 'PROFILE_EXIT'
              ? t(`Вы уверены, что хотите выйти?`)
              : actionName == 'SALEPRODUCTS'
              ? t(`Удалить выбранные товары?`)
              : t(`Удалить ${itemName}?`)}
          </NewText>
          <NewText color={'grey'}>
            {actionName == 'SALES'
              ? t('Если вы отмените эту продажу, операция не будет обработана.')
              : actionName == 'PROFILE_EXIT'
              ? ''
              : t(`Удалив ${itemName}, вы потеряете всю информацию. Это действие не обратимо.`)}
          </NewText>
        </TextWrap>
        <ButtonsWrap
          $column
          $gap={'18px'}
        >
          <NewButton
            typeBtn={'red'}
            fullWidth
            onClick={() => deleteItem(actionName, modalPayload)}
          >
            {actionName === 'SALES' ? t('Отменить продажу') : actionName === 'PROFILE_EXIT' ? t('Выйти') : t('Удалить')}
          </NewButton>
          <NewButton
            fullWidth
            onClick={closeModal}
          >
            {actionName === 'SALES' ? t('Продолжить') : t('Отмена')}
          </NewButton>
        </ButtonsWrap>
      </ModalContent>
    </ModalWrapper>
  );
};
export default NewCommonConfirmDelete;
