import styled from 'styled-components';

import NewButton from '../../../../shared/new-ui/NewButton/ui/NewButton';
import { FlexWithAlign } from '../../../../utils/styleUtils';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  padding-left: 48px;
  overflow-y: scroll;
  max-height: 650px;
`;

export const RegistrationPolicyWrapper = styled(FlexWithAlign)`
  padding: 24px;
`;
export const TopWrapper = styled(FlexWithAlign)`
  margin-bottom: 24px;
`;

export const CloseButton = styled(NewButton)`
  border: none;
  padding: 6px;
  min-height: 36px;
  min-width: 36px;
`;
